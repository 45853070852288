import React from 'react';
import cn from 'classnames';
import Clearfix from '@dealroadshow/uikit/core/components/Clearfix';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import { Icon, IconType } from '@dealroadshow/uikit';
import responsiveModalStyles from '@dealroadshow/uikit/core/components/Modal/responsiveModal.scss';
import { useFilesystemContext } from '@/dataroom/application/filesystem/filesystemActions/FilesystemContext';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import FolderInputs from './FolderInputs';
import FolderIcon from '@/dataroom/ui/common/FilesystemIcon/FolderIcon';
import styles from './createFolderSection.scss';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import { canManageSettingsLite } from '@/dataroom/domain/managePermissions';
import DataroomTenantConfig from '@/dataroom/application/config/DataroomTenantConfig';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';

const CREATE_FOLDER_FOLDERS_FIELD = 'folders';

interface IProps {
  isPermissionsSectionVisible: boolean,
  userHasSystemManageAccess: boolean,
  isValid: boolean,
  closeModal: () => void,
  onShowPermissionsSection: () => void,
  fieldComponent: React.ElementType,
  fieldArrayComponent: React.ElementType,
  footerRefCallback: () => void,
  parentFolder: IFolderTree,
}

const CreateFolderSection = (props: IProps) => {
  const {
    isPermissionsSectionVisible,
    userHasSystemManageAccess,
    isValid,
    closeModal,
    onShowPermissionsSection,
    fieldComponent: Field,
    fieldArrayComponent: FieldArray,
    footerRefCallback,
    parentFolder,
  } = props;

  const { createFolder: { isCreating } } = useFilesystemContext();
  const { currentUser } = useCurrentUserContext();
  const { tenant } = useDataroomTenantContext();
  const isUserAdminLite = canManageSettingsLite(currentUser);
  const { isPermissionGroupsCreationEnabled } = DataroomTenantConfig.fromCode(tenant).options;
  const isPermissionButtonVisible =
    isPermissionGroupsCreationEnabled &&
    ((userHasSystemManageAccess && !isPermissionsSectionVisible) || isUserAdminLite);

  return (
    <div className={ styles.sectionWrp }>
      <FolderIcon
        className={ styles.folderIcon }
        isStaging={ parentFolder.isStaging }
      />

      <div className={ styles.titleWrp }>
        Please name your new folder(s):
      </div>
      <FieldArray name={ CREATE_FOLDER_FOLDERS_FIELD }>
        { ({ fields }) => (
          <FolderInputs
            fields={ fields }
            fieldComponent={ Field }
          />
        ) }
      </FieldArray>

      <div
        className={ cn(styles.buttonsWrp, {
          [styles.isPermissionsSectionVisible]: isPermissionsSectionVisible,
          [responsiveModalStyles.modalFooter]: !isPermissionsSectionVisible,
        }) }
        ref={ footerRefCallback }
      >
        <Button
          type="submit"
          variant={ ButtonVariantType.action }
          disabled={ isPermissionsSectionVisible || !isValid || isCreating }
          className={ styles.submitButton }
          title="Create"
          dataTest="createFolderButton"
        />
        <Button
          variant={ ButtonVariantType.text }
          onClick={ closeModal }
          disabled={ isPermissionsSectionVisible }
          className={ styles.cancelButton }
          title="Cancel"
          dataTest="cancelCreatingFolderButton"
        />
        { isPermissionButtonVisible && (
          <Tooltip
            containerClassName={ styles.tooltipContainer }
            content={ 'Not available based on your permissions' }
            disabled={ !isUserAdminLite }
            placement="top"
          >
            <Button
              variant={ ButtonVariantType.text }
              className={ styles.changePermissionsButton }
              onClick={ onShowPermissionsSection }
              disabled={ isCreating || isUserAdminLite }
              dataTest="changePermissionsButton"
            >
              <Icon
                className={ styles.changePermissionsIcon }
                type={ IconType.lock }
              />
              <span className={ styles.changePermissionsButtonText }>Change Permissions</span>
            </Button>
          </Tooltip>
        ) }
        <Clearfix />
      </div>
    </div>
  );
};

export default CreateFolderSection;
