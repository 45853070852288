import { IconType } from '@dealroadshow/uikit';
import styles from './permissionGroups.scss';
import { permissionType } from '@/dataroom/domain/filesystemPermissions';

export const permissionGroups = {
  manage: {
    name: permissionType.manage,
    label: 'Manage',
    icon: IconType.settings,
  },
  viewAndDownload: {
    name: permissionType.viewAndDownload,
    label: 'Download',
    icon: IconType.download,
  },
  viewOnly: {
    name: permissionType.viewOnly,
    label: 'View only',
    icon: IconType.display,
  },
  noAccess: {
    name: permissionType.noAccess,
    label: 'No access',
    icon: IconType.lock,
  },
  listOnly: {
    name: permissionType.listOnly,
    label: 'List only',
    icon: null,
  },
};

export const permissionGroupsClassNames = {
  [permissionGroups.manage.name]: styles.managePermission,
  [permissionGroups.viewAndDownload.name]: styles.viewAndDownloadPermission,
  [permissionGroups.viewOnly.name]: styles.viewOnlyPermission,
  [permissionGroups.noAccess.name]: styles.noAccessPermission,
};
