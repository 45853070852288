import React from 'react';
import cn from 'classnames';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { Tooltip, Icon, IconType } from '@dealroadshow/uikit';
import styles from './toolbarIconButton.scss';

interface IProps {
  className?: string,
  buttonClassName?: string,
  iconClassName?: string,
  title: string | React.ReactNode,
  iconType: IconType,
  isDisabled?: boolean,
  onClick: (e) => void,
  dataTest: string,
}

const ToolbarIconButton = ({
  className,
  buttonClassName,
  iconClassName,
  title,
  iconType,
  isDisabled,
  onClick,
  dataTest,
}: IProps) => (
  <div className={ cn(styles.toolbarIconButtonWrp, className) }>
    <Tooltip
      placement="bottom"
      content={ title }
      hideOnClick={ !isDisabled }
      wrpClassName={ styles.tooltipWrp }
      containerClassName={ styles.tooltipContainer }
    >
      <Button
        className={ cn(styles.toolbarIconButton, buttonClassName) }
        variant={ ButtonVariantType.link }
        onClick={ onClick }
        disabled={ isDisabled }
        dataTest={ dataTest }
      >
        <Icon
          className={ cn(styles.toolbarIcon, iconClassName) }
          type={ iconType }
        />
      </Button>
    </Tooltip>
  </div>
);

export default ToolbarIconButton;
