import React from 'react';
import cn from 'classnames';
import { Checkbox } from '@/Framework/UI/Molecules/CheckBoxArray';
import { permissionGroupsClassNames } from '@/dataroom/ui/common/PermissionGroups/constants';
import styles from './permissionGroupCheckbox.scss';
import permissionGroupsStyles from '../permissionGroups.scss';
import { IFile } from '@/dataroom/domain/vo/permissionGroups/compare/File';
import { IFolder } from '@/dataroom/domain/vo/permissionGroups/compare/Folder';
import { PermissionGroup } from '@/dataroom/domain/vo/types/PermissionGroup';

interface IProps {
  permissionGroup: {
    name: string,
    label: string,
    icon: string,
  },
  className?: string,
  controlClassName?: string,
  name: string,
  value: string | {},
  disabled?: boolean,
  reversedArrays?: string[],
  checked?: boolean,
  isUnCheckEnable?: boolean,
  onChange?: (f: IFile | IFolder, p: PermissionGroup) => void,
  isReadOnly?: boolean,
}

const PermissionGroupCheckbox = (props: IProps) => {
  const { permissionGroup, className, controlClassName, ...otherProps } = props;
  return (
    <Checkbox
      checkboxGroupName={ otherProps.name }
      { ...otherProps }
      className={ cn(
        styles.checkboxWrp,
        className,
        permissionGroupsStyles.checkboxWrp,
        { [styles.readOnly]: otherProps.isReadOnly },
      ) }
      controlClassName={ cn(
        styles.checkboxControl,
        permissionGroupsStyles.checkboxControl,
        permissionGroupsClassNames[permissionGroup.name],
        controlClassName,
      ) }
      dataTest={ `${ otherProps.name }Checkbox` }
    />
  );
};

export default PermissionGroupCheckbox;
