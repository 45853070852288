import { IconType } from '@dealroadshow/uikit';
import getDataroomBasedUrl from '@/dataroom/infrastructure/getDataroomBasedUrl';

export default (dataroom, isQuestionManager) => {
  const { name: dataroomName } = dataroom;
  const questionsPath = getDataroomBasedUrl(dataroomName, 'questions');

  return {
    qaSnapshot: {
      key: 'qa-snapshot',
      title: 'Q&A Snapshot',
      path: `${ questionsPath }/snapshot`,
      icon: IconType.analytics,
      isVisible: isQuestionManager,
      isCountLabelFetching: false,
      count: 0,
    },
    openQuestions: {
      key: 'open-questions',
      title: 'Open Questions',
      path: `${ questionsPath }/open-questions`,
      icon: IconType.chat,
      isVisible: true,
      isCountLabelFetching: false,
      count: 0,
    },
    resolvedQuestions: {
      key: 'resolved-questions',
      title: 'Resolved Questions',
      path: `${ questionsPath }/resolved-questions`,
      icon: IconType.checkCircle,
      isVisible: true,
      isCountLabelFetching: false,
      count: 0,
    },
  };
};
