import cn from 'classnames';
import React from 'react';
import { iconMapping } from '@/dataroom/ui/common/FilesystemIcon/constants';
import { Icon } from '@dealroadshow/uikit';

interface IProps {
  className: string,
}

const LinkIcon = (props: IProps) => {
  const { type, className } = iconMapping.url;

  return (
    <span className={ cn(className, props.className) }>
      <Icon type={ type } data-test="linkIcon" />
    </span>
  );
};

export default LinkIcon;
