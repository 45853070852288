import React, { useRef, useEffect, memo } from 'react';
import cn from 'classnames';
import { Input, IconType } from '@dealroadshow/uikit';
import Popover from '@dealroadshow/uikit/core/components/Popover';
import { IFinalFormApi } from '@/Framework/UI/Organisms/FinalForm/interfaces';
import styles from './searchInput.scss';

interface IProps {
  className?: string,
  emptyClassName?: string,
  fieldComponent: IFinalFormApi['Field'],
  isMobile: boolean,
  isFormOpened: boolean,
  handleClick: () => void,
  isError: boolean,
  isEmpty: boolean,
  isDisabled?: boolean,
}

const Search = memo(({
  className,
  emptyClassName,
  fieldComponent: Field,
  isMobile,
  handleClick,
  isFormOpened,
  isError,
  isEmpty,
  isDisabled,
}: IProps) => {
  const popoverRef = useRef(null);

  useEffect(() => {
    isError ? popoverRef.current?.show() : popoverRef.current?.hide();
  }, [isError]);

  return (
    <Popover
      content="Please enter 3 or more characters"
      className={ styles.popover }
      contentClassName={ styles.popoverContent }
      arrowClassName={ styles.arrow }
      ref={ popoverRef }
      placement="bottom"
      minPadding={ 0 }
      usePortal={ false }
      triggerEvent={ false }
      closeOnClickOutside={ false }
      renderOnlyVisible
    >
      <span>
        <Field
          component={ Input }
          name="searchText"
          placeholder="Search by Name & Type"
          iconType={ isMobile && IconType.search }
          onClick={ handleClick }
          isNarrow={ !isFormOpened || isMobile }
          isClearable
          className={ cn(className, {
              [emptyClassName]: emptyClassName && isEmpty,
              [styles.mobileInput]: isMobile,
              [styles.mobileInputError]: isMobile && isError,
            },
          ) }
          dataTest="dataroomNavigationSearchInput"
          isErrorMessage={ !isMobile }
          isDisabled={ isDisabled }
        />
      </span>
    </Popover>
  );
});

export default Search;
