import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import noop from 'lodash/noop';
import useRouter from '@/Framework/hooks/useNextRouter';
import { Icon, IconType } from '@dealroadshow/uikit';
import { responsiveModalStyles } from '@dealroadshow/uikit/core/components/Modal';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import FinalForm from '@/Framework/UI/Organisms/FinalForm';
import QuestionsBlock from './QuestionsBlock';
import QuestionType from './QuestionType';
import QuestionLimitsMessage from '@/dataroom/ui/common/Questions/QuestionLimitsMessage';
import { IFile } from '@/dataroom/domain/questions/vo/File';
import validate from './validator';
import { ICategoryAction, ICreateAction, IFileAction } from '@/dataroom/domain/questions/vo/questionAction';
import { IQuestionLimits } from '@/dataroom/domain/questions/vo/QuestionLimits';
import { TFinalFormRenderProp } from '@/Framework/UI/Organisms/FinalForm/interfaces';

import styles from './questionForm.scss';

interface IProps {
  initialValues: any,
  closeModal: (v?: boolean) => void,
  onInitialized: () => void,
  setFormDirty: (d: boolean) => void,
  file: IFile,
  currentUser: any,
  create: ICreateAction,
  categories: ICategoryAction,
  files: IFileAction,
  onSuccessCallback: () => void,
  footerRefCallback: () => void,
  isFormDirty: boolean,
  isFormLoading: boolean,
  baseUrl: string,
  questionLimits: IQuestionLimits,
}

const filterEmptyQuestions = (questions) => questions
  .filter((question) => question.questionText);

const QuestionsForm = ({
  initialValues,
  closeModal,
  onInitialized,
  setFormDirty,
  file,
  currentUser,
  create,
  files,
  categories,
  onSuccessCallback = noop,
  footerRefCallback,
  isFormDirty,
  isFormLoading,
  baseUrl,
  questionLimits,
}: IProps) => {
  const [isFileEditMode, setIsFileEditMode] = useState(false);
  const { push } = useRouter();

  const {
    createQuestions,
    isFetching,
  } = create;
  const { isFetching: isFilesFetching } = files;

  const {
    collection: categoriesCollection,
    getCategoryList,
    isFetching: isCategoryFetching,
  } = categories;

  const [selectedFile, setSelectedFile] = useState(file);

  const {
    isFetching: isFetchingLimits,
    getQuestionLimits,
    limits,
    updateLimitsRemaining,
    isAllLimitsReached,
    activePriority,
    priorityRemain,
    initialPriority,
    isMaxQuantity,
  } = questionLimits;

  const handleFormSubmit = (formData) => createQuestions(
    filterEmptyQuestions(formData.questions),
    selectedFile,
    isAllLimitsReached,
  )
    .then(() => {
      closeModal(true);
      onSuccessCallback();
    });

  const handleBulkUploadClick = () => {
    push(`${ baseUrl }/bulk-upload-questions`);
    closeModal(true);
  };

  useEffect(() => {
    Promise.all([getCategoryList(), getQuestionLimits()])
      .then(() => onInitialized());
  }, []);

  const renderForm: TFinalFormRenderProp = useCallback(({
    pristine,
    invalid,
  }, {
    Field,
    FieldArray,
    FormSpy,
  }) => (isFormLoading ? null : (
    <div className={ styles.contentWrp }>
      <FormSpy
        onChange={ ({
          dirty,
          values,
        }) => {
          // @ts-ignore
          updateLimitsRemaining(values);
          setFormDirty(dirty);
        } }
        subscription={ { values: true } }
      />
      <QuestionLimitsMessage limits={ limits } />
      <QuestionType
        setSelectedFile={ setSelectedFile }
        isFileEditMode={ isFileEditMode }
        setIsFileEditMode={ setIsFileEditMode }
        selectedFile={ selectedFile }
        files={ files }
      />
      <div className={ styles.submitterBlock }>
        <div className={ styles.submitterLabel }>Submitted by:</div>
        <div>
          <span className={ styles.submitterName }>
            { currentUser.firstName } { currentUser.lastName }
          </span>{ ' ' }
          <span className={ styles.submitterCompany }>({ currentUser.companyName })</span>
        </div>
      </div>
      <QuestionsBlock
        fieldComponent={ Field }
        fieldArrayComponent={ FieldArray }
        categoriesCollection={ categoriesCollection }
        activePriority={ activePriority }
        priorityRemain={ !limits.isNoLimit && priorityRemain }
        isAllLimitsReached={ isAllLimitsReached }
        initialPriority={ initialPriority }
        isMaxQuantity={ isMaxQuantity }
      />
      <div
        className={ cn(responsiveModalStyles.modalFooter, styles.footerWrapper) }
        ref={ footerRefCallback }
      >
        <Button
          type="submit"
          variant={ ButtonVariantType.action }
          className={ styles.submitButton }
          disabled={
            pristine ||
            invalid ||
            isFetching ||
            isFileEditMode
          }
          title="Submit"
          dataTest="submitButton"
        />
        <Button
          type="button"
          variant={ ButtonVariantType.text }
          className={ styles.cancelButton }
          onClick={ () => closeModal() }
          title="Cancel"
          dataTest="cancelButton"
        />
        <Button
          type="button"
          variant={ ButtonVariantType.text }
          className={ styles.bulkUploadButton }
          dataTest="bulkUploadQuestionLink"
          onClick={ handleBulkUploadClick }
        >
          <span>
            Bulk Upload{ ' ' }
            <span className={ styles.mobileHidden }>
              Questions
            </span>
            <Icon
              type={ IconType.arrowRight }
              className={ styles.arrowRight }
            />
          </span>
        </Button>
      </div>
      <Spinner
        overlay
        isVisible={ isFetching }
      />
    </div>
  )), [
    isFilesFetching,
    limits,
    isMaxQuantity,
    isAllLimitsReached,
    initialPriority,
    priorityRemain,
    activePriority,
    isFetchingLimits,
    isCategoryFetching,
    isFetching,
    isFileEditMode,
    isFormDirty,
    isFormLoading,
    selectedFile,
  ]);

  return (
    // @ts-ignore
    <FinalForm
      dataTest="askQuestionForm"
      name="askQuestionForm"
      validate={ validate }
      initialValues={ initialValues }
      onSubmit={ handleFormSubmit }
      render={ renderForm }
      keepDirtyOnReinitialize
    />
  );
};

export default QuestionsForm;
