import React, { useRef } from 'react';
import { DataroomURL } from '@/dataroom/infrastructure/dataroomUrl';
import { Icon, IconType } from '@dealroadshow/uikit';
import Popover, { PopoverRef } from '@dealroadshow/uikit/core/components/Popover';
import styles from './troubleFeedback.scss';

const contactUsLink = (<a href={ DataroomURL.getContactUsUrl() } target="_blank">contact us</a>);

const TroubleFeedback = () => {
  const popoverRef = useRef<PopoverRef>(null);

  const handlePopoverClose = () => {
    popoverRef.current.hide();
  };

  const popover = (
    <div className={ styles.troubleFeedbackPopoverContent }>
      <Icon
        type={ IconType.close }
        className={ styles.popoverCloseButton }
        onClick={ handlePopoverClose }
      />
      We are committed to providing you world class service and support.
      If you are experiencing upload issues, please { contactUsLink }
    </div>
  );

  return (
    <div className={ styles.troubleFeedbackContainer }>
      <span className={ styles.troubleFeedbackTitle }>
        Having trouble?
      </span>
      <Popover
        ref={ popoverRef }
        wrapperClassName={ styles.toggleIconWrapper }
        contentClassName={ styles.troubleFeedbackPopoverContentWrapper }
        placement="bottom"
        content={ popover }
        minPadding={ 0 }
        isDarkTheme
        usePortal={ false }
        renderOnlyVisible
      >
        <Icon
          type={ IconType.question }
          className={ styles.toggleIcon }
        />
      </Popover>
    </div>
  );
};

export default TroubleFeedback;
