export const PREMIUMIZATION = 'premiumization';
export const EXPORT_EXCEL = 'exportExcel';
export const OPENMARKET = 'openmarket';
export const CREDIT_FLOW_ARTICLES_FEATURE = 'creditFlowArticles';

export const featureToggle = {
  [PREMIUMIZATION]: {
    cookieName: PREMIUMIZATION,
  },
  [EXPORT_EXCEL]: {
    cookieName: EXPORT_EXCEL,
  },
  [OPENMARKET]: {
    cookieName: OPENMARKET,
  },
  [CREDIT_FLOW_ARTICLES_FEATURE]: {
    cookieName: CREDIT_FLOW_ARTICLES_FEATURE,
  },
};
