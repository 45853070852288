import React from 'react';
import { CountryFlag } from '@dealroadshow/uikit';
import styles from './dialInPopover.scss';

interface IProps {
  countryCode: string,
  countryName: string,
  phoneCode: string,
  phoneNumber: string,
  description: string,
}

const DialInItem = ({
  countryCode,
  countryName,
  phoneCode,
  phoneNumber,
  description,
}: IProps) => (
  <div className={ styles.row }>
    <div className={ styles.dialInCountry }>
      <CountryFlag
        className={ styles.flagIcon }
        countryCode={ countryCode?.toLowerCase() }
      />
      <span className={ styles.dialInCountryName }>
        { `${ countryName }${ description ? ` (${ description })` : '' }` }
      </span>
    </div>
    <span className={ styles.dialInPhone }>
      { `${ phoneCode ? `${ phoneCode } ` : '' }${ phoneNumber }` }
    </span>
  </div>
);

export default DialInItem;
