import React, { useEffect, useRef, useState } from 'react';
import { ListChildComponentProps } from 'react-window';
import isEqual from 'lodash/isEqual';
import htmlCollectionForEach from '@/Framework/dataHelpers/htmlCollection/forEach';
import { useVirtualDataTableContext } from '../VirtualDataTableContext';
import {
  BodyRow,
  BodyRowDraggable,
  BodyRowPure,
  BodyRowDraggablePure,
  getUniqueIdentifier,
} from '@dealroadshow/uikit';
import { TVirtualBody } from '../interfaces';

const VirtualBodyRow = ({ index, virtualDataTableRef }: ListChildComponentProps & {
  virtualDataTableRef?: React.RefObject<TVirtualBody>,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const virtualBodyRowRef = useRef<HTMLTableRowElement>(null);
  const {
    setSize,
    dataTableProps: {
      data,
      isUsePureUpdateComponent,
      isDraggable,
      isAnimation,
      rowCallbacks,
      rowClassNameCallback,
      columns,
      selectedRows,
      bodyRef,
      syncBodyRef,
      customCellProps,
    },
  } = useVirtualDataTableContext();

  const rowData = data[index];

  const BodyRowComponent = isUsePureUpdateComponent ? BodyRowPure : BodyRow;
  const BodyRowDraggableComponent = isUsePureUpdateComponent ? BodyRowDraggablePure : BodyRowDraggable;
  const VirtualBodyRowComponent = isDraggable || isAnimation ? BodyRowDraggableComponent : BodyRowComponent;

  useEffect(() => {
    let size = 0;

    htmlCollectionForEach(virtualBodyRowRef.current?.children as HTMLCollection, (item) => {
      const { height } = item.getBoundingClientRect();
      if (!size || size < height) {
        size = height;
      }
    });

    setSize(virtualDataTableRef, index, size);
  }, [setSize, index]);

  const handleHover = (isHovered) => {
    setIsHovered(isHovered);

    const syncInnerRef: HTMLDivElement = syncBodyRef?.current.props.innerRef?.current;
    const innerRef: HTMLDivElement = bodyRef?.current?.props.innerRef?.current;

    innerRef && innerRef.querySelectorAll('tbody tr').forEach((child: HTMLElement) => {
      if (+child.dataset.row === index && isHovered) {
        child.classList.add('is-hovered');
      } else {
        child.classList.remove('is-hovered');
      }
    });

    syncInnerRef && htmlCollectionForEach(syncInnerRef.children, (child: HTMLElement) => {
      if (+child.dataset.row === index && isHovered) {
        child.classList.add('is-hovered');
      } else {
        child.classList.remove('is-hovered');
      }
    });
  };

  return (
    <VirtualBodyRowComponent
      ref={ virtualBodyRowRef }
      key={ rowData.key ?? index }
      rowIndex={ index }
      rowData={ rowData }
      rowCallbacks={ rowCallbacks }
      rowClassNameCallback={ rowClassNameCallback }
      columns={ columns }
      isSelected={ selectedRows?.some(isEqual.bind(this, rowData)) }
      className={ rowData.className }
      isAnimation={ isAnimation }
      uniqueIdentifier={ getUniqueIdentifier(index) }
      isLastChild={ data.length - 1 === index }
      isDragAndDropDisabled={ rowData.isDragAndDropDisabled }
      onMouseOverCapture={ handleHover.bind(this, true) }
      onMouseLeave={ handleHover.bind(this, false) }
      isHovered={ isHovered }
      customCellProps={ customCellProps }
    />
  );
};

export default VirtualBodyRow;
