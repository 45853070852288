import React, { ElementType } from 'react';
import { GroupBase } from 'react-select';
import {
  Select as ReactSelect,
  AsyncSelect as ReactAsyncSelect,
  CreatableSelect as ReactCreatableSelect,
  AsyncCreatableSelect as ReactAsyncCreatableSelect,
  ISelectOption,
  TSelectCommonProps,
  ISelectCustomProps,
} from '@dealroadshow/uikit';

import { IFinalFormFieldInputComponentProps } from '@/Framework/UI/Organisms/FinalForm/interfaces';

type TSelectProps<SOption extends ISelectOption, IsMulti extends boolean, Group extends GroupBase<SOption>> =
  ISelectCustomProps<SOption, IsMulti>
  & TSelectCommonProps<SOption, IsMulti, Group>
  & IFinalFormFieldInputComponentProps<SOption>;

const withFormInput = (SelectComponent: ElementType) => <
  SOption extends ISelectOption = ISelectOption,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<SOption> = GroupBase<SOption>,
> ({ input, ...otherProps }: TSelectProps<SOption, IsMulti, Group>) => (
  <SelectComponent
    { ...otherProps }
    name={ input.name }
    value={ input.value }
    onBlur={ input.onBlur }
    onChange={ input.onChange }
  />
);

const Select = withFormInput(ReactSelect);
const AsyncSelect = withFormInput(ReactAsyncSelect);
const CreatableSelect = withFormInput(ReactCreatableSelect);
const AsyncCreatableSelect = withFormInput(ReactAsyncCreatableSelect);

export {
  Select,
  AsyncSelect,
  CreatableSelect,
  AsyncCreatableSelect,
};
