import { useState } from 'react';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { useDIContext } from '@/Framework/DI/DIContext';
import RedactionRepository from '@/dataroom/infrastructure/repository/RedactionRepository';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { NotificationManager } from '@/Framework/Notification';
import { getMessage } from '@/Framework/Message/Mapper/getMessage';
import { messageCodes } from '@/Framework/Message/messages';

export default function useRemove() {
  const { container } = useDIContext();
  const { dataroom } = useDataroomContext();

  const [isFetching, setIsFetching] = useState<boolean>(false);

  const removeAllRedactions = async (fileId: number) => {
    setIsFetching(true);

    try {
      const payload = {
        dataroomId: dataroom.id,
        fileId,
      };

      const redactionRepository = container.get<RedactionRepository>(RedactionRepository);

      await redactionRepository.removeAllRedactions(payload);
      NotificationManager.success(getMessage(messageCodes.DATAROOM_UNREDACT_FILE_SUCCESS));
    } catch (error) {
      container.get(DataroomErrorHandler)
        .handleError(error);
      throw error;
    } finally {
      setIsFetching(false);
    }
  };
  const removeAppliedRedactions = async (fileId: number) => {
    setIsFetching(true);

    try {
      const payload = {
        dataroomId: dataroom.id,
        fileId,
      };

      const redactionRepository = container.get<RedactionRepository>(RedactionRepository);

      await redactionRepository.removeAppliedRedactions(payload);
      NotificationManager.success(getMessage(messageCodes.DATAROOM_REMOVE_REDACTED_FILE_SUCCESS));
    } catch (error) {
      container.get(DataroomErrorHandler)
        .handleError(error);
      throw error;
    } finally {
      setIsFetching(false);
    }
  };

  return {
    isFetching,
    removeAllRedactions,
    removeAppliedRedactions,
  };
}
