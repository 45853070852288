import { Dependencies } from 'constitute';
import ErrorCodeHelper from '@finsight/error-codes';
import { v4 as uuid } from 'uuid';
import { RpcNotification, RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import Request from '@/Framework/api/Rpc/Request';
import RpcDispatcher from '@/dataroom/application/DI/Rpc/HttpDispatcher';
import SocketClient from '@/dataroom/application/DI/Socket/Client';
import AbstractDataroomRepository from '@/dataroom/infrastructure/repository/AbstractDataroomRepository';
import { IFileUploadEmailAlerts } from '@/dataroom/domain/vo/emailAlerts/FileUploadEmailAlerts';
import { IStagingFileUploadEmailAlerts } from '@/dataroom/domain/vo/emailAlerts/StagingFileUploadEmailAlerts';
import { IDigestEmailAlerts } from '@/dataroom/domain/vo/emailAlerts/DigestEmailAlerts';
import { IAccessRequestEmailAlerts } from '@/dataroom/domain/vo/emailAlerts/AccessRequestEmailAlerts';
import { IQuestionsEmailAlerts } from '@/dataroom/domain/vo/emailAlerts/QuestionsEmailAlerts';
import { IUser } from '@/dataroom/domain/vo/User';
import { IUserItem } from '@/dataroom/domain/vo/users/UserItem';
import UsersUploadDispatcher from '@/dataroom/application/DI/FileUpload/UsersUploadDispatcher';
import { getMessage } from '@/Framework/Message/Mapper/getMessage';
import { NotificationManager } from '@/Framework/Notification';
import { messageCodes } from '@/Framework/Message/messages';
import { IRealtime } from '@/dataroom/domain/vo/emailAlerts/IRealtime';
import { IDaily } from '@/dataroom/domain/vo/emailAlerts/IDaily';
import { IWeekly } from '@/dataroom/domain/vo/emailAlerts/IWeekly';
import { UploadEvent } from '@dealroadshow/file-uploader';

@Dependencies(RpcDispatcher, UsersUploadDispatcher, SocketClient)
class UserRepository extends AbstractDataroomRepository {
  constructor(
    protected rpc: typeof RpcDispatcher,
    private readonly usersUploadDispatcher: typeof UsersUploadDispatcher,
    private socketClient: typeof SocketClient,
  ) {
    super(rpc);
  }

  updateUploadNotificationSettings = async (
    payload: {
      dataroomName,
      notifications: {
        realtime: IRealtime,
        daily: IDaily,
        weekly: IWeekly,
      },
      userIds?: number[],
    },
  ): Promise<void> => {
    const request = new Request('dataroom.users.update_upload_notification_settings', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  updateStagingUploadNotificationSettings = async (payload: {
    dataroomName: string,
    notifications: {
      stagingRealtime: IRealtime,
      stagingDaily: IDaily,
      stagingWeekly: IWeekly,
    },
    userIds?: number[],
  }): Promise<void> => {
    const request = new Request('dataroom.users.update_staging_notification_settings', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  updateDigestNotificationSettings = async (
    payload: {
      dataroomName: string,
      notifications: {
        digestDaily: IDaily,
        digestWeekly: IWeekly,
      },
      userIds?: number[],
    },
  ): Promise<void> => {
    const request = new Request('dataroom.users.update_digest_notification_settings', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  /**
   * Updates Access Request Notification Settings.
   */
  updateAccessRequestNotificationSettings = async (
    payload: {
      dataroomName: string,
      notifications: {
        accessRequestRealtime: IRealtime,
        accessRequestDaily: IDaily,
        accessRequestWeekly: IWeekly,
      },
      userIds?: number[],
    },
  ): Promise<void> => {
    const request = new Request('dataroom.users.update_access_request_notification_settings', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  updateQuestionNotificationSettings = async (
    payload: {
      dataroomName: string,
      notifications: IQuestionsEmailAlerts,
      userIds?: number[],
    },
  ): Promise<void> => {
    const request = new Request('qna.user.notification_settings.update', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  getCurrent = async (payload: { dataroomId: number }): Promise<IUser> => {
    const request = new Request('dataroom.users.get_current', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getListing = async (
    payload: {
      dataroomId : number,
      filter: string,
      sortBy: string,
      sortOrder: string,
      page : number,
      perPage : number,
    },
  ): Promise<IUserItem[]> => this.abortableCall(new Request('dataroom.users.list', payload));

  getListingByPermissionGroup = async (
    payload: {
      dataroomId : number,
      filter: string,
      sortBy: string,
      sortOrder: string,
      page : number,
      perPage : number,
      permissionGroupId: number,
    },
  ): Promise<IUserItem[]> => this.abortableCall(new Request('dataroom.users.list_assign_to_permission_group', payload));

  addUsers = async (
    payload: {
      dataroomId: number,
      fileUploadAlerts: IFileUploadEmailAlerts,
      stagingFileUploadAlerts?: IStagingFileUploadEmailAlerts,
      digestAlerts: IDigestEmailAlerts,
      accessRequestAlerts?: IAccessRequestEmailAlerts,
      qnaAlerts?: IQuestionsEmailAlerts,
      emails: string[],
      notification: {
        send: boolean,
        customMessage: string,
        customSubject: string,
        contactName?: string,
        contactEmail?: string,
      },
      permissionGroupIds: number[],
      permissionGroups: ({ id: number })[],
      onFinish: () => void,
      onError: (error) => void,
    },
  ): Promise<void> => {
    const {
      onFinish,
      onError,
      ...otherPayload
    } = payload;

    const requestUuid = uuid();

    const subscribeReq = new Request('dataroom.users.add.listen', {
      uuid: requestUuid,
    });

    const subscription = await this.socketClient().subscribe(subscribeReq);

    subscription
      .on('users.add.completed', () => {
        subscription.cancel();
        onFinish();
      })
      .on('users.add.error', (notification: RpcNotification) => {
        subscription.cancel();
        onError(notification.params.payload || {});
      });

    const request = new Request('dataroom.users.add', {
      uuid: requestUuid,
      ...otherPayload,
    });
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  composeUsers = async (
    payload: {
      dataroomId: number,
      notification: {
        customMessage: string,
        customSubject: string,
        contactName?: string,
        contactEmail?: string,
      },
      userIds: number[],
    },
  ): Promise<void> => {
    const request = new Request('dataroom.users.compose', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  composeAllUsers = async (
    payload: {
      dataroomId: number,
      notification: {
        customMessage: string,
        customSubject: string,
        contactName?: string,
        contactEmail?: string,
      },
    },
  ): Promise<void> => {
    const request = new Request('dataroom.users.compose_all', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  composeNotInvitedUsers = async (
    payload: {
      dataroomId: number,
      notification: {
        customMessage: string,
        customSubject: string,
        contactName?: string,
        contactEmail?: string,
      },
    },
  ): Promise<void> => {
    const request = new Request('dataroom.users.compose_not_invited', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  composeInfo = async (
    payload: {
      dataroomId: number,
    },
  ): Promise<{
    totalUsers: number,
    notInvitedUsers: number,
  }> => {
    const request = new Request('dataroom.users.compose_info', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  addToPermissionGroups = async (
    payload: {
      dataroomId: number,
      permissionGroupIds: number[],
      userIds: number[],
    },
  ): Promise<void> => {
    const request = new Request('dataroom.users.permission_groups.add', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  removeFromPermissionGroups = async (
    payload: {
      dataroomId: number,
      permissionGroupIds: number[],
      userIds: number[],
    },
  ): Promise<void> => {
    const request = new Request('dataroom.users.permission_groups.remove', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  revokePermissionGroups = async (
    payload: {
      dataroomId: number,
      userIds: number[],
    },
  ): Promise<void> => {
    const request = new Request('dataroom.users.revoke_access', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  revertPermissionGroups = async (
    payload: {
      dataroomId: number,
      userIds: number[],
    },
  ): Promise<void> => {
    const request = new Request('dataroom.users.revert_access', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  deleteUsers = async (
    payload: {
      dataroomId: number,
      userIds: number[],
    },
  ): Promise<boolean> => {
    const request = new Request('dataroom.users.delete', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  checkUsersEmailsExist = async (
    payload: {
      dataroomId: number,
      emails: string[],
    },
  ): Promise<boolean> => {
    const request = new Request('dataroom.users.emails_exist', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult();
  };

  checkUserHasPrimaryAccess = async (
    payload: {
      dataroomId: number,
      userId: number,
    },
  ): Promise<boolean> => {
    const request = new Request('user.has_primary_access', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().payload.hasAccess;
  };

  canCreateDataroom = async (): Promise<boolean> => {
    let req = new Request('user.can_create_dataroom');
    let response = await this.rpc().call<RpcSuccess>(req);

    return response.getResult().payload.isAllowed;
  };

  uploadExcelUsers = async (payload: {
    dataroomId: number,
    dataroomName: string,
    file: File,
    onSuccess: (success: any) => void,
    onError: (error: any) => void,
  }) => {
    const upload = await this.usersUploadDispatcher(payload.dataroomName)
      .upload(payload.file, { dataroomId: payload.dataroomId });

    upload.on(UploadEvent.uploadDone, (result) => {
      if (result.error || result.errors) {
        if (result.code === ErrorCodeHelper.getCodeByName('INVALID_ARGUMENTS')) {
          NotificationManager.error(getMessage(messageCodes.GENERAL_ERROR));
        }
        payload.onError(result);
      } else {
        payload.onSuccess(result?.data);
      }
    });
  };
}

export default UserRepository;
