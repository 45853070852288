import config from '@/Framework/config';
import buildUrl from '@/Framework/url/helpers/buildUrl';
import base from '@/Framework/url/baseUrl';

export default {
  /**
   * Get base users url
   *
   * @param {Array||String} uris
   *
   * @return {String}
   */
  getUrl(...uris) {
    return buildUrl(config.protocol, `${ config.drsSubdomain }.${ config.globalDomain }`, ...uris);
  },

  /**
   * Get legacy url
   *
   * @param {Array||String} uris
   *
   * @return {String}
   */
  getLegacyUrl(...uris) {
    return buildUrl(config.protocol, config.drsLegacyDomain, ...uris);
  },

  /**
   * Get base files url
   *
   * @param {Array||String} uris
   *
   * @return {String||Array}
   */
  getBaseFilesUrl(...uris) {
    return base.getApiUrl('dealroadshow/files', ...uris);
  },

  /**
   * Get file url
   *
   * @param {String||Array} uris
   *
   * @return {String}
   */
  getFileUrl(...uris) {
    return this.getBaseFilesUrl(...uris);
  },
};
