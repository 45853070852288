import styles from './filesystemIcon.scss';
import { IconType } from '@dealroadshow/uikit';

const jpegImage: {
  type: IconType,
  className: string,
} = {
  type: IconType.fileJpg,
  className: styles.jpeg,
};

const audio: {
  type: IconType,
  className: string,
} = {
  type: IconType.audio,
  className: styles.audio,
};

const video: {
  type: IconType,
  className: string,
} = {
  type: IconType.video,
  className: styles.video,
};

const document: {
  type: IconType,
  className: string,
} = {
  type: IconType.fileDoc,
  className: styles.doc,
};

const excel: {
  type: IconType,
  className: string,
} = {
  type: IconType.fileXls,
  className: styles.xls,
};

const presentation: {
  type: IconType,
  className: string,
} = {
  type: IconType.filePpt,
  className: styles.ppt,
};

const archive: {
  type: IconType,
  className: string,
} = {
  type: IconType.fileArchive,
  className: styles.archive,
};

const rootStagingFolder: {
  type: IconType,
  className: string,
} = {
  type: IconType.lock,
  className: styles.stagingFolder,
};

export const iconMapping: {
  [key: string]: {
    type: IconType,
    className: string,
  },
} = {
  folderOpen: {
    type: IconType.folderOpen,
    className: styles.folder,
  },
  folderClosed: {
    type: IconType.folder,
    className: styles.folder,
  },
  pdf: {
    type: IconType.filePdf,
    className: styles.pdf,
  },
  png: {
    type: IconType.filePng,
    className: styles.png,
  },
  jpg: jpegImage,
  jpeg: jpegImage,
  tiff: {
    type: IconType.fileTiff,
    className: styles.tiff,
  },
  ico: {
    type: IconType.image,
    className: styles.image,
  },
  wav: audio,
  mp3: audio,
  mov: video,
  mpeg: video,
  mp4: video,
  avi: video,
  doc: document,
  docx: document,
  xls: excel,
  xlsx: excel,
  csv: {
    type: IconType.fileCsv,
    className: styles.csv,
  },
  cdi: {
    type: IconType.fileCdi,
    className: styles.cdi,
  },
  ppt: presentation,
  pptx: presentation,
  txt: {
    type: IconType.fileTxt,
    className: styles.txt,
  },
  xml: {
    type: IconType.fileXml,
    className: styles.xml,
  },
  zip: archive,
  rar: archive,
  gz: archive,
  tar: archive,
  url: {
    type: IconType.link,
    className: styles.url,
  },
};

export const stagingIconMapping: {
  [key: string]: {
    type: IconType,
    className: string,
  },
} = {
  folderOpen: {
    type: IconType.folderOpen,
    className: styles.stagingFolder,
  },
  folderClosed: {
    type: IconType.folder,
    className: styles.stagingFolder,
  },
};

export const rootStagingIconMapping: {
  [key: string]: {
    type: IconType,
    className: string,
  },
} = {
  folderOpen: rootStagingFolder,
  folderClosed: rootStagingFolder,
};

export const defaultIcon: {
  type: IconType,
  className: string,
} = {
  type: IconType.document,
  className: styles.file,
};
