import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { DataroomTenant } from '@/dataroom/domain/vo/types/DataroomTenant';
import { useSessionContext } from '@/users/application/Session/SessionContext';
import CheckAccessPermissions from '@/users/application/Session/CheckAccessPermissions';
import DataroomAppContext from '@/dataroom/application/DataroomAppContext';
import { useDownloadContext } from '@/dataroom/application/DownloadContext';
import { useExportToExcelContext } from '@/dataroom/application/ExportToExcelContext';
import DocumentViewer from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DocumentViewer';
import ApplicationWrp from '@/Framework/UI/Templates/ApplicationWrp';
import Disclaimer from '../components/Disclaimer';
import EsmaSelfAssign from '../components/EsmaSelfAssign';
import Header from '@/dataroom/ui/components/Dataroom/Header';
import Sidebar from '@/dataroom/ui/components/Dataroom/Sidebar';
import { Spinner } from '@dealroadshow/uikit';
import LayoutWithBanner from '@/Framework/UI/Templates/LayoutWithBanner';
import LayoutContentWrp from './LayoutContentWrp';
import ContextMenu from '@/dataroom/ui/components/Dataroom/components/ContextMenu';
import Favicon from '@/dataroom/ui/common/Favicon';
import { contextMenuId } from '@/dataroom/ui/components/Dataroom/components/ContextMenu/constants';
import DialInsSection from '@/dataroom/ui/components/Dataroom/components/DialInsSection';
import DownloadModals from '@/dataroom/ui/common/Download/DownloadModals';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useBookmarkingContext } from '@/dataroom/application/BookmarkingContext';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import { usePrimaryFolderTreeContext } from '@/dataroom/application/folderTree/PrimaryFolderTreeContext';
import { useStagingFolderTreeContext } from '@/dataroom/application/folderTree/StagingFolderTreeContext';
import { useCurrentFolderContext } from '@/dataroom/application/CurrentFolderContext';
import { FOOTER_PORTAL_ID } from '@/dataroom/ui/components/Dataroom/components/FooterPortalWrp/constants';
import headersStyles from '@dealroadshow/uikit/core/styles/headers.scss';
import pageStyles from '@/Framework/GlobalStyles/page.scss';
import styles from './dataroomLayout.scss';
import { SidebarTab } from '@/dataroom/ui/components/Dataroom/Sidebar/constants';
import SidebarTabSwitcher from '@/dataroom/ui/components/Dataroom/Sidebar/SidebarTabSwicher';
import AccessRestrictedModal from '@/dataroom/ui/common/Modals/AccessRestrictedModal';
import PreparingArchivesCount from '@/dataroom/ui/components/Dataroom/components/PreparingArchivesCount';

interface IProps {
  children: React.ReactElement,
  dataroomName: string,
}

function DataroomLayout({ children, dataroomName }: IProps) {
  const [isMobileMenuCollapsed, setMobileMenuCollapsed] = useState(true);

  const { dataroom, getDataroomByName } = useDataroomContext();
  const { getBookmarks } = useBookmarkingContext();
  const { currentUser, getCurrentUser, canUserAccessStaging, canUserAccessPrimary } = useCurrentUserContext();
  const { primaryFolderTree } = usePrimaryFolderTreeContext();
  const { isStagingInitialized } = useStagingFolderTreeContext();

  const { isDownloadProcessing } = useDownloadContext();
  const { isExportCreating } = useExportToExcelContext();
  const { currentFolder } = useCurrentFolderContext();
  const isProcessing = isDownloadProcessing || isExportCreating;

  useEffect(() => {
    getDataroomByName(dataroomName);
  }, [dataroomName]);

  useEffect(() => {
    if (currentFolder) {
      getBookmarks(currentFolder.isStaging);
    }
  }, [currentFolder]);

  useEffect(() => {
    if (dataroom) {
      getCurrentUser();
    }
  }, [dataroom?.id]);

  const isPrimaryAreaInitialized = (canUserAccessPrimary && primaryFolderTree) || !canUserAccessPrimary;
  const isStagingAreaInitialized = (canUserAccessStaging && isStagingInitialized) || !canUserAccessStaging;

  const isDataroomInitialized = dataroom &&
                                currentUser &&
                                (canUserAccessPrimary || canUserAccessStaging) &&
                                isPrimaryAreaInitialized &&
                                isStagingAreaInitialized;

  return (
    <>
      <Favicon />
      <ApplicationWrp className={ styles.applicationWrp }>
        { isDataroomInitialized && (
          <LayoutWithBanner>
            <div className={ styles.layoutWrp }>
              <EsmaSelfAssign>
                <Disclaimer>
                  <Header setMobileMenuCollapsed={ setMobileMenuCollapsed } />
                  <Sidebar
                    isMobileMenuCollapsed={ isMobileMenuCollapsed }
                    setMobileMenuCollapsed={ setMobileMenuCollapsed }
                  />
                  <LayoutContentWrp>
                    <div className={ cn(pageStyles.pageHeader, styles.pageHeader) }>
                      <div
                        className={ cn(
                          pageStyles.pageHeaderTitle,
                          headersStyles.isH2,
                          styles.pageHeaderTitle,
                        ) }
                        data-test="pageHeaderTitle"
                      >
                        { dataroom.displayName }
                      </div>
                    </div>
                    <div className={ styles.contentCardWrp }>
                      { children }
                      <Spinner
                        overlayClassName={ cn(styles.spinnerOverlay, {
                          [styles.partlySpinnerOverlay]: isProcessing,
                        }) }
                        className={ styles.spinner }
                        overlay
                        isVisible={ isProcessing }
                      />
                    </div>
                    <DialInsSection className={ styles.dialInsSection } />
                  </LayoutContentWrp>
                  <ContextMenu id={ contextMenuId } />
                  <DownloadModals />
                  <DocumentViewer />
                  <AccessRestrictedModal
                    fromDataroom
                    dataroomDisplayName={ dataroom.displayName }
                    dataroom={ dataroom }
                    isStaging={ currentFolder?.isStaging }
                  />
                </Disclaimer>
              </EsmaSelfAssign>
            </div>
          </LayoutWithBanner>
        ) }
        { !isDataroomInitialized && (
          <Spinner
            overlay
            overlayClassName={ styles.overlay }
          />
        ) }
        <PreparingArchivesCount />
        <div
          id={ FOOTER_PORTAL_ID }
          className={ styles.footerPortalWrp }
        />
      </ApplicationWrp>
    </>
  );
}

export default ({ children, dataroomName, tenant, sidebarTabKey }: IProps & {
  tenant: DataroomTenant,
  sidebarTabKey: SidebarTab,
}) => (
  <CheckAccessPermissions onInit={ useSessionContext().switchToManagerAccess }>
    <DataroomAppContext tenant={ tenant }>
      <DataroomLayout dataroomName={ dataroomName }>
        <SidebarTabSwitcher tabKey={ sidebarTabKey }>
          { children }
        </SidebarTabSwitcher>
      </DataroomLayout>
    </DataroomAppContext>
  </CheckAccessPermissions>
);
