import React from 'react';

import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { Icon, IconType } from '@dealroadshow/uikit';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import useEditQuestionAlertsModal
  from '@/dataroom/ui/components/Dataroom/Sidebar/Questions/EditQuestionAlertsModal/useEditQuestionAlertsModal';
import * as questionPermissions from '@/dataroom/domain/questionPermissions';
import useAskQuestionModal from '@/dataroom/ui/components/Dataroom/components/AskQuestionModal/useAskQuestionModal';
import { useQuestionsExplorerContext } from '@/dataroom/ui/common/Questions/QuestionsExplorer/QuestionsExplorerContext';
import { EmailAlertsText } from '@/dataroom/ui/common/EmailAlerts/EmailAlertsTextContent/EmailAlertsText';

import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import styles from './questionActions.scss';

const QuestionActions = () => {
  const { currentUser } = useCurrentUserContext();

  const {
    AskQuestionModal,
    isQuestionModalVisible,
    showAskQuestionModal,
    hideAskQuestionModal,
  } = useAskQuestionModal();

  const {
    EditQuestionAlertsModal,
    isVisible,
    showEditQuestionAlertsModal,
    hideEditQuestionAlertsModal,
  } = useEditQuestionAlertsModal();

  const {
    getCollection,
  } = useQuestionsExplorerContext();

  const canAskQuestion = questionPermissions.canLeaveQuestion(currentUser);

  const tooltipContent = currentUser.qnaIsLimitReached
    ? 'The question limit has been reached'
    : 'Posting new questions has been disabled by the Q&A Manager';

  return (
    <div>
      <Button
        variant={ ButtonVariantType.text }
        className={ styles.emailAlertsButton }
        onClick={ showEditQuestionAlertsModal }
        dataTest="changePermissionsButton"
      >
        <Icon
          className={ styles.emailAlertsIcon }
          type={ IconType.settings }
        />
        <span className={ styles.emailAlertsButtonText }>
          Q&A Email Alerts:{ ' ' }
          <EmailAlertsText
            alertSettings={ currentUser.qnaNotificationSettings }
            isShort
          />
        </span>
      </Button>
      <Tooltip
        content={ tooltipContent }
        containerClassName={ styles.tooltipWrp }
        className={ styles.tooltip }
        maxWidth="207px"
        disabled={ canAskQuestion }
      >
        <Button
          type="button"
          variant={ ButtonVariantType.action }
          className={ styles.askQuestionButton }
          title="Ask a Question"
          dataTest="askQuestionButton"
          disabled={ !canAskQuestion }
          onClick={ showAskQuestionModal }
        />
      </Tooltip>
      { isQuestionModalVisible && (
        <AskQuestionModal
          isVisible
          closeModal={ hideAskQuestionModal }
          onSuccessCallback={ getCollection }
        />
      ) }
      { isVisible && (
        <EditQuestionAlertsModal
          closeModal={ hideEditQuestionAlertsModal }
        />
      ) }
    </div>
  );
};

export default QuestionActions;
