import React, { useState, createContext, useContext } from 'react';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import { useDIContext } from '@/Framework/DI/DIContext';
import FolderRepository from '@/dataroom/infrastructure/repository/FolderRepository';
import FilesystemRepository from '@/dataroom/infrastructure/repository/FilesystemRepository';
import { NotificationManager } from '@/Framework/Notification';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { isFolder } from '@/dataroom/domain/filesystem';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useCurrentFolderContext } from '@/dataroom/application/CurrentFolderContext';
import { SortOrder } from '@dealroadshow/uikit';
import { usePrimaryFolderTreeContext } from '@/dataroom/application/folderTree/PrimaryFolderTreeContext';
import { useStagingFolderTreeContext } from '@/dataroom/application/folderTree/StagingFolderTreeContext';

const PER_PAGE_MAX = 1000;
const SORT_BY_DEFAULT = 'fileIndex';
const SORT_ORDER_DEFAULT: SortOrder = 'asc';
const PER_PAGE_DEFAULT = 25;
const PAGE_DEFAULT = 1;

const useDnDFileIndexes = () => {
  const { container } = useDIContext();
  const { dataroom } = useDataroomContext();
  const { currentFolderId } = useCurrentFolderContext();
  const {
    getPrimaryFolderTree,
  } = usePrimaryFolderTreeContext();
  const {
    getStagingFolderTree,
  } = useStagingFolderTreeContext();

  const [isFetching, setIsFetching] = useState(false);
  const [isDnDFileIndexesActive, setDnDFileIndexesActive] = useState(false);
  const [isDnDFileIndexesPristine, setDnDFileIndexesPristine] = useState(false);
  const [indexes, setIndexes] = useState<{
    fileIndexCurrent: number,
    fileIndexFull: string,
  }[]>([]);
  const [collection, setCollection] = useState<IFilesystemListItem[]>([]);
  const [foldersNumber, setFoldersNumber] = useState(0);
  const [filesNumber, setFilesNumber] = useState(0);
  const [changedFolderIds, setChangedFolderIds] = useState<number[]>([]);
  const [changedFileIds, setChangedFileIds] = useState<number[]>([]);
  const [perPage, setPerPage] = useState(PER_PAGE_DEFAULT);
  const [page, setPage] = useState(PAGE_DEFAULT);
  const [isFileIndexesButtonDisabled, setFileIndexesButtonDisabled] = useState(true);
  const [isStagingActive, setIsStagingActive] = useState(false);
  const [isFileIndexesSaved, setFileIndexesSaved] = useState(false);

  const isChangeIndexesButtonVisible = perPage <= PER_PAGE_MAX;

  const isPinned = (item: IFilesystemListItem) => item.isFileIndexCustom;

  const saveIndexes = async () => {
    setIsFetching(true);
    const tmpCollection = [...collection];
    const folders = tmpCollection.splice(0, foldersNumber);
    const foldersPayload = folders
      .filter((folder) => changedFolderIds
        .includes(folder.id)).map((item) => ({ id: item.id, index: item.fileIndexCurrent }));
    const filesPayload = tmpCollection
      .filter((file) => changedFileIds
        .includes(file.id)).map((item) => ({ id: item.id, index: item.fileIndexCurrent }));

    try {
      const filesystemRepository = container.get<FilesystemRepository>(FilesystemRepository);
      await filesystemRepository.editIndexes(
        {
          dataroomId: dataroom.id,
          folders: foldersPayload,
          files: filesPayload,
        },
      );
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    } finally {
      if (isStagingActive) {
        getStagingFolderTree();
      } else {
        getPrimaryFolderTree();
      }
      setIsFetching(false);
      setFileIndexesSaved(true);
      setDnDFileIndexesPristine(false);
    }
  };

  const turnOnDnDFileIndexesMode = async (isStaging?: boolean) => {
    setIsFetching(true);
    setDnDFileIndexesActive(true);
    setIsStagingActive(isStaging);
    const payload = {
      page,
      perPage,
      folderId: currentFolderId,
      dataroomId: dataroom.id,
      sortBy: SORT_BY_DEFAULT,
      sortOrder: SORT_ORDER_DEFAULT,
      search: '',
      withFilesCount: false,
    };

    try {
      const folderRepository = container.get<FolderRepository>(FolderRepository);
      const result = await folderRepository.getListing(payload);

      if (result) {
        setCollection(result.collection.map((item) => {
          item.isStaging = !!isStaging;
          return item;
        }));
        setIndexes(result.collection.map((item) => (
          {
            fileIndexFull: item.fileIndexFull,
            fileIndexCurrent: item.fileIndexCurrent,
          }
        )));
        const currentFoldersNumber = result.collection.filter((item) => isFolder(item)).length;
        setFoldersNumber(currentFoldersNumber);
        setFilesNumber(result.collection.length - currentFoldersNumber);
      }
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    } finally {
      setIsFetching(false);
    }
  };

  const addItemToResponse = (item: IFilesystemListItem) => {
    if (isFolder(item)) {
      if (!changedFolderIds.find((id) => item.id === id)) {
        setChangedFolderIds((prevIds) => [...prevIds, item.id]);
      }
    } else if (!changedFileIds.find((id) => item.id === id)) {
        setChangedFileIds((prevIds) => [...prevIds, item.id]);
      }
  };

  const isPinnedAfter = (oldPosition: number, newPosition: number) => {
    const directionCoefficient = newPosition > oldPosition ? 1 : 0;
    const activeItem = collection[newPosition];
    let bottomItems: IFilesystemListItem[];
    if (directionCoefficient) {
      // move down
      if (isFolder(activeItem)) {
        bottomItems = collection.slice(oldPosition + directionCoefficient, foldersNumber);
      } else {
        bottomItems = collection.slice(oldPosition + directionCoefficient);
      }
    } else {
      // move up
      // eslint-disable-next-line no-lonely-if
      if (isFolder(activeItem)) {
        bottomItems = collection.slice(newPosition + directionCoefficient, foldersNumber);
      } else {
        bottomItems = collection.slice(newPosition + directionCoefficient);
      }
    }

    return bottomItems.filter((item) => item.id !== activeItem.id).some((item) => item.isFileIndexCustom);
  };

  const getModifiedCollection = (oldCollection: IFilesystemListItem[], oldPosition: number, newPosition: number) => {
    const currentCollection = [...oldCollection];
    const activeItem = currentCollection[oldPosition];
    const oldItem = currentCollection[newPosition];

    // move to pined item strategy or no pinned items after
    if (oldItem.isFileIndexCustom || !isPinnedAfter(oldPosition, newPosition)) {
      // cut active
      currentCollection.splice(oldPosition, 1);
      // put active
      currentCollection.splice(newPosition, 0, { ...activeItem, isFileIndexCustom: true });
      return currentCollection;
    }
    // move to unpinned item strategy
    const pinnedQueue = [];
    const pinnedCollection = [...currentCollection.reduce((result, item, i) => {
      // handle active item
      if (i === newPosition) {
        result.push({ ...activeItem, isFileIndexCustom: true });

        // handle old position
      } else if (i === oldPosition) {
        result.push(pinnedQueue.shift());

        // handle pinned item
      } else if (isPinned(item)) {
        // take pinned from queue first
        if (pinnedQueue.length) {
          result.push(pinnedQueue.shift());
          pinnedQueue.push(item);
        } else {
          result.push(item);
        }

        // handle empty or item from queue
      } else {
        result.push(pinnedQueue.shift());
      }
      return result;
    }, [])];

    const unPinnedCollection =
      [...currentCollection.filter((item) => {
        return !item.isFileIndexCustom && (item.id !== activeItem.id || item.type !== activeItem.type);
      })];

    let unpinnedCollectionIteration = 0;
    const mergedCollection = pinnedCollection.map((item) => {
      if (item) return item;
      const itemToMerge = unPinnedCollection[unpinnedCollectionIteration];
      unpinnedCollectionIteration++;
      return itemToMerge;
    });
    return mergedCollection;
  };

  // eslint-disable-next-line consistent-return
  const switchOrder = (oldPosition: number, newPosition: number) => {
    const activeItem = collection[oldPosition];

    // prevent pin when file or folder is only one
    if ((isFolder(activeItem) && foldersNumber === 1) || (!isFolder(activeItem) && filesNumber === 1)) {
      return false;
    }

    setDnDFileIndexesPristine(true);
    let newIndex: number;

    if (isFolder(activeItem)) {
      newIndex = newPosition < foldersNumber ? newPosition : foldersNumber - 1;
    } else {
      newIndex = newPosition >= foldersNumber ? newPosition : foldersNumber;
    }

    const newCollection = getModifiedCollection(collection, oldPosition, newIndex).map((item, i) => {
      item.fileIndexFull = indexes[i].fileIndexFull;
      item.fileIndexCurrent = indexes[i].fileIndexCurrent;
      return item;
    });

    addItemToResponse(newCollection[newIndex]);
    setCollection(newCollection);
  };

  const turnOffDnDFileIndexesMode = () => {
    setDnDFileIndexesActive(false);
    setIndexes([]);
    setCollection([]);
    setFoldersNumber(0);
    setFilesNumber(0);
    setChangedFolderIds([]);
    setChangedFileIds([]);
    setDnDFileIndexesPristine(false);
    setIsStagingActive(false);
    setFileIndexesSaved(false);
  };

  return {
    isFetching,
    isDnDFileIndexesActive,
    isDnDFileIndexesPristine,
    turnOnDnDFileIndexesMode,
    turnOffDnDFileIndexesMode,
    collection,
    switchOrder,
    saveIndexes,
    setPerPage,
    setPage,
    isChangeIndexesButtonVisible,
    setFileIndexesButtonDisabled,
    isFileIndexesButtonDisabled,
    isFileIndexesSaved,
    setFileIndexesSaved,
  };
};

type DnDFileIndexesContextType = ReturnType<typeof useDnDFileIndexes>;

export const DnDFileIndexesContext = createContext<DnDFileIndexesContextType>(null);

export function useDnDFileIndexesContext() {
  const context = useContext(DnDFileIndexesContext);
  if (!context) {
    throw new Error('useDnDFileIndexesContext must be used within a DnDFileIndexesContextProvider');
  }
  return context;
}

interface IProps {
  children: React.ReactNode,
}

function DnDFileIndexesContextProvider({ children }: IProps) {
  return (
    <DnDFileIndexesContext.Provider value={ useDnDFileIndexes() }>
      { children }
    </DnDFileIndexesContext.Provider>
  );
}

export default DnDFileIndexesContextProvider;
