import cn from 'classnames';
import React from 'react';
import { defaultIcon, iconMapping } from '@/dataroom/ui/common/FilesystemIcon/constants';
import { Icon } from '@dealroadshow/uikit';

interface IProps {
  extension: string,
  className?: string,
}

const FileIcon = (props: IProps) => {
  let key = props.extension;
  const { type, className } = iconMapping[key] || defaultIcon;

  return (
    <span className={ cn(className, props.className) }>
      <Icon type={ type } />
    </span>
  );
};

export default FileIcon;
