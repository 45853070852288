import React from 'react';
import cn from 'classnames';
import noop from 'lodash/noop';
import { Icon, IconType } from '@dealroadshow/uikit';
import styles from './toggleIcon.scss';

interface IProps {
  isOpen?: boolean,
  onClick?: () => void,
  className?: string,
  style?: object,
}

const ToggleIcon = (
  {
    isOpen,
    onClick = noop,
    className,
    style,
  }: IProps,
) => {
  const toggleIcon = isOpen ? IconType.collapse : IconType.expand;

  return (
    <Icon
      className={ cn(styles.toggleIcon, className) }
      onClick={ onClick }
      type={ toggleIcon }
      style={ style }
      data-test="toggleIconButton"
    />
  );
};

export default ToggleIcon;
