import React from 'react';
import { Modal } from '@dealroadshow/uikit';
import PhysicalCopyForm from './PhysicalCopyForm';
import { useSessionContext } from '@/users/application/Session/SessionContext';
import { IDataroomListItem } from '@/dataroom/domain/vo/collection/DataroomListItem';
import { IDataroom } from '@/dataroom/domain/vo/Dataroom';
import PhysicalDeliveryProvider from '@/dataroom/application/PhysicalDeliveryContext';

interface IProps {
  dataroom: IDataroomListItem | IDataroom,
  onClose: () => void,
}

const PhysicalCopyModal = ({ dataroom, onClose }: IProps) => {
  const { currentUser } = useSessionContext();

  return (
    <Modal
      onCloseClicked={ onClose }
      isVisible
      dataTest="dataroomPhysicalCopyModal"
    >
      <PhysicalCopyForm
        dataroom={ dataroom }
        onClose={ onClose }
        currentUser={ currentUser }
      />
    </Modal>
  );
};

export default (props) => (
  <PhysicalDeliveryProvider>
    <PhysicalCopyModal { ...props } />
  </PhysicalDeliveryProvider>
);
