import React from 'react';
import { Icon, IconType } from '@dealroadshow/uikit';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import dataroomUrl from '@/dataroom/infrastructure/dataroomUrl';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import { useSessionContext } from '@/users/application/Session/SessionContext';

import styles from '@/dataroom/ui/components/Dataroom/Sidebar/Footer/footer.scss';

function Logout() {
  const { tenant } = useDataroomTenantContext();
  const { logout } = useSessionContext();
  const action = (
    <a
      onClick={ () => logout({ redirect: false }) }
      href={ dataroomUrl(tenant)
        .getLogoutUrl() }
      className={ styles.leftAlignedAction }
      data-test="logoutButton"
    >
      <Icon type={ IconType.exit } />
    </a>
  );

  return (
    <div className={ styles.leftAlignedIcon }>
      <Tooltip
        content="Logout"
        placement="top"
      >
        { action }
      </Tooltip>
    </div>
  );
}

export default Logout;
