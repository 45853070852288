import React, { useEffect } from 'react';
import cn from 'classnames';
import ActionButton from '@/Framework/UI/Atoms/Button/ActionButton';
import { variantTypes } from '@/Framework/UI/Atoms/Button/ActionButton/constants';
import hoverActionsStyles from '@/Framework/UI/Molecules/Tables/HoverActions/hoverActions.scss';
import { IconType } from '@dealroadshow/uikit';
import { useInfoPanelContext } from '@/dataroom/application/InfoPanelContext';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';

interface IProps {
  className?: string,
  buttonClassName?: string,
  selectedItems?: IFilesystemListItem[],
  item?: IFilesystemListItem,
  variant?: string,
  title?: string,
  isMobile?: boolean,
  dataTest: string,
}

const InfoButton = (
  {
    className,
    buttonClassName,
    selectedItems = [],
    item,
    variant = variantTypes.icon,
    title = 'View Details',
    dataTest,
    isMobile,
  }: IProps,
) => {
  const { showInfoPanel, hideInfoPanel, setSelectedItem, infoItem, isInfoPanelVisible } = useInfoPanelContext();

  useEffect(() => {
    if (!isMobile) setSelectedItem(selectedItems[selectedItems.length - 1]);
  }, [isMobile, selectedItems.map(({ id }) => id).join()]);

  useEffect(() => {
    if (
      isInfoPanelVisible &&
      item &&
      infoItem &&
      (infoItem.id === item.id) &&
      (infoItem.type === item.type) &&
      (infoItem.name !== item.name)
    ) {
      setSelectedItem(item || selectedItems[selectedItems.length - 1]);
    }
  }, [item]);

  const handleShowInfo = () => {
    if (isInfoPanelVisible) {
      hideInfoPanel();
    } else {
      setSelectedItem(item || selectedItems[selectedItems.length - 1]);
      showInfoPanel();
    }
  };

  return (
    <ActionButton
      dataTest={ dataTest }
      className={ cn(className, {
        [hoverActionsStyles.textActionItem]: variant === variantTypes.text,
      }) }
      buttonClassName={ buttonClassName }
      title={ title }
      variant={ variant }
      iconType={ IconType.info }
      onClick={ handleShowInfo }
    />
  );
};

export default InfoButton;
