import React from 'react';
import useRouter from '@/Framework/hooks/useNextRouter';
import NavigationItem from '@/dataroom/ui/components/Dataroom/Sidebar/Navigation/NavigationItem';
import { IconType } from '@dealroadshow/uikit';
import getDataroomBasedUrl from '@/dataroom/infrastructure/getDataroomBasedUrl';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import { Area } from '@/dataroom/domain/vo/Area';

interface IProps {
  className: string,
}

const FileIndexItem = ({ className }: IProps) => {
  const { dataroom } = useDataroomContext();
  const { canUserAccessPrimary, canUserAccessStaging } = useCurrentUserContext();
  const { asPath: pathname } = useRouter();

  return (
    <NavigationItem
      path={
        getDataroomBasedUrl(
          dataroom.name,
          `file-index${ canUserAccessPrimary && canUserAccessStaging ? `/${ Area.Primary }` : '' }`,
        )
      }
      isActive={ pathname.startsWith(getDataroomBasedUrl(dataroom.name, 'file-index')) }
      icon={ IconType.list }
      title="File Index"
      className={ className }
    />
  );
};

export default FileIndexItem;
