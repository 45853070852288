import React from 'react';
import noop from 'lodash/noop';
import cn from 'classnames';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { Spinner, Icon, IconType } from '@dealroadshow/uikit';
import styles from './iconTextButton.scss';

interface IProps {
  className?: string,
  isDisabled?: boolean,
  isFetching?: boolean,
  onClick?: (e) => void,
  dataTest: string,
  label: string | React.ReactNode,
  icon: IconType,
}

const IconTextButton = ({
  className,
  icon,
  label,
  isDisabled,
  isFetching = false,
  onClick = noop,
  dataTest,
}: IProps) => (
  <Button
    variant={ ButtonVariantType.text }
    className={ cn(styles.iconTextButton, className) }
    onClick={ onClick }
    disabled={ isDisabled }
    dataTest={ dataTest }
  >
    <Spinner
      isVisible={ isFetching }
      size="small"
    />
    <div className={ cn(styles.iconTextWrapper, {
      [styles.isFetchingIconTextButton]: isFetching,
    }) }
    >
      <Icon
        className={ styles.iconText }
        type={ icon }
      />
      <div className={ styles.iconLabel }>{ label }</div>
    </div>
  </Button>
);

export default IconTextButton;
