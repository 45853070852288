import React, { useState } from 'react';
import cn from 'classnames';
import { Icon, IconType } from '@dealroadshow/uikit';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import Search from '@/dataroom/ui/components/Dataroom/Sidebar/Navigation/Search';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import { useDnDFileIndexesContext } from '@/dataroom/application/DnDFileIndexesContext';
import config from '@/dataroom/application/config/config';
import styles from './header.scss';

interface IProps {
  setMobileMenuCollapsed: (collapsed: boolean) => void,
}

const Header = ({ setMobileMenuCollapsed }: IProps) => {
  const [isSearchVisible, setSearchVisible] = useState(false);
  const [isSearchOpened, setIsSearchOpened] = useState(false);
  const { dataroom } = useDataroomContext();
  const { tenant } = useDataroomTenantContext();
  const { isDnDFileIndexesActive } = useDnDFileIndexesContext();

  const handleClickMobileMenu = () => {
    !isDnDFileIndexesActive && setMobileMenuCollapsed(false);
  };

  return (
    <div className={ cn(styles.header, { [styles.dealvdrHeader]: tenant === config.tenant.tenantDealvdr.code }) }>
      <span className={ styles.iconOuter }>
        <Icon
          type={ IconType.hamburgerMenu }
          className={ styles.icon }
          onClick={ handleClickMobileMenu }
        />
      </span>
      {
        isSearchVisible ? (
          <div className={ styles.searchWrapper }>
            <Search
              inputClassName={ styles.search }
              emptyInputClassName={ styles.searchEmpty }
              isOpened={ isSearchOpened }
              setOpened={ setIsSearchOpened }
              isDisabled={ isDnDFileIndexesActive }
            />
          </div>
        ) : (
          <span className={ styles.title }>
            { dataroom.displayName }
          </span>
        )
      }
      <span
        className={ cn(
          styles.iconOuter,
          { [styles.visibleSearchWrp]: isSearchVisible },
        ) }
        onClick={ () => setSearchVisible(!isSearchVisible) }
      >
        <Icon
          type={ isSearchVisible ? IconType.close : IconType.search }
          className={ cn(styles.icon) }
        />
      </span>
    </div>
  );
};

export default Header;
