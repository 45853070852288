import React from 'react';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import { Icon, IconType } from '@dealroadshow/uikit';
import { ResponsiveModal as Modal } from '@dealroadshow/uikit/core/components/Modal';
import styles from './conflictModal.scss';
import { isFolder, isRoot } from '@/dataroom/domain/filesystem';
import {
  conflictResolving,
  conflictTypes,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/ConflictModal/constants';
import Alert from '@/dataroom/ui/components/Dataroom/components/Alert/Alert';
import { IFilesystemItem } from '@/dataroom/domain/vo/filesystem/FilesystemItem';
import { IChangePermissionsType } from '@/dataroom/domain/vo/filesystem/ChangePermissionsType';

interface IProps {
  onCloseConflictModal: () => void,
  conflictType: string,
  from: any,
  successItems: IFilesystemItem[],
  destinationFolder?: IFilesystemItem,
  conflictItems: any,
  action: (item: {}) => void,
  permissionGroups?: IChangePermissionsType,
  isFetching: boolean,
  fromStagingToPrimary?: boolean,
}

const ConflictModal = (
  {
    onCloseConflictModal,
    conflictType,
    successItems,
    conflictItems,
    destinationFolder,
    from,
    permissionGroups,
    action,
    isFetching = false,
    fromStagingToPrimary = false,
  }: IProps,
) => {
  let totalCount = successItems.length + conflictItems.length;

  let conflictFiles = conflictItems.filter((item) => !isFolder(item));

  let conflictS = conflictFiles.length > 1 ? 's' : '';
  let totalS = conflictFiles.totalCount > 1 ? 's' : '';

  function resolve(resolveMethod) {
    conflictItems.forEach((item) => {
      item.resolving = resolveMethod;
    });
    action({
      filesystemItems: conflictItems,
      destinationFolder,
      successItems,
      permissionGroups,
      successCallback: onCloseConflictModal,
      payload: fromStagingToPrimary ? { fromStagingToPrimary } : null,
    });
  }

  const keepBoth = () => {
    resolve(conflictResolving.keepBoth);
  };

  const skip = () => {
    resolve(conflictResolving.skip);
  };

  const overwrite = () => {
    resolve(conflictResolving.overwrite);
  };

  let content = (
    <div className={ styles.contentWrp }>
      <div>
        {
          conflictType === conflictTypes.restore ? (
            <>{ conflictType } <strong>{ totalCount } item{ totalS }</strong> from { from.name }.</>
          ) : (
            <>
              { conflictType } { totalCount } item{ totalS } from <strong>“{ from.name }”</strong>
              { isRoot(from) && <> (root folder)</> }
              {
                !isRoot(destinationFolder) ? (
                  <> to <strong>“{ destinationFolder.name }”</strong>.</>
                ) : (
                  <> to <strong>“{ destinationFolder.name }”</strong> (root folder).</>
                )
              }
            </>
          )
        }
      </div>

      <div>The destination has <strong>{ conflictFiles.length } file{ conflictS }</strong> with same name.</div>

      <Alert className={ styles.alert }>
        <div className={ styles.resolvingMethod }>
          <h4>Replace { conflictItems[0].type }{ conflictS }</h4>
          Replace file{ conflictS } in the destination folder.
        </div>
        <div className={ styles.resolvingMethod }>
          <h4>Create Copy</h4>
          Rename file{ conflictS } by adding a numerical prefix at the end of the file name{ conflictS }.<br />
          ie. File_name.pdf will be renamed to File_name (2).pdf.
        </div>
        <div className={ styles.resolvingMethod }>
          <h4>Skip</h4>
          Skip file{ conflictS } with the same name.
        </div>
      </Alert>
      <Spinner
        overlay
        isVisible={ isFetching }
      />
    </div>
  );

  const footer = (
    <div className={ styles.buttonsWrp }>
      <div className={ styles.actionButtons }>
        <Button
          variant={ ButtonVariantType.action }
          type="submit"
          disabled={ isFetching }
          onClick={ overwrite }
          title="Replace"
          dataTest="replaceButton"
        />
        <Button
          variant={ ButtonVariantType.text }
          onClick={ keepBoth }
          disabled={ isFetching }
          className={ styles.createCopyButton }
          dataTest="createCopyButton"
        >
          <Icon type={ IconType.copy } />
          Create Copy
        </Button>
      </div>
      <Button
        variant={ ButtonVariantType.text }
        disabled={ isFetching }
        onClick={ skip }
        title="Skip"
        dataTest="skipButton"
      />
    </div>
  );

  return (
    <Modal
      className={ styles.conflictModal }
      onCloseClicked={ onCloseConflictModal }
      title={ `Replace File${ conflictS }, Create Copy or Skip` }
      footer={ footer }
      isVisible
      dataTest="conflictModal"
    >
      <div>{ content }</div>
    </Modal>
  );
};

export default ConflictModal;
