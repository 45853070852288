import React from 'react';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';
import { Checkbox } from '@dealroadshow/uikit';
import { defaultCheckboxGroupName, IValues, Value } from './constants';

interface IProps {
  value: Value,
  allValues: IValues,
  change: (newAllValues: IValues) => void,
  reversedArrays: [],
  disabled: boolean,
  className: string,
  controlClassName: string,
  labelClassName: string,
  name: string,
  isUnCheckEnable: boolean,
  onChange: (value: Value, name: string) => void,
  checked: boolean,
  checkboxGroupName: string,
  dataTest: string,
}

const BaseCheckboxArray = (
  {
    checkboxGroupName = defaultCheckboxGroupName,
    name,
    value,
    change,
    reversedArrays = [],
    disabled,
    className,
    controlClassName,
    labelClassName,
    allValues,
    isUnCheckEnable = true,
    onChange = noop,
    checked = false,
    dataTest,
  }: IProps,
) => {
  let currentNameValues = allValues[checkboxGroupName] || [];

  const reversedArraysVales = reversedArrays.reduce((acc, reversedArray) => {
    acc[reversedArray] = allValues[reversedArray];

    return acc;
  }, {});

  const changeValue = (event) => {
    let newAllValues = allValues;
    let newValues = currentNameValues.filter((oldValue) => !isEqual(oldValue, value));
    // @ts-ignore
    if ((event.target.checked || !isUnCheckEnable) && !value.isDisabled) { // was checked
      newValues = currentNameValues.concat(value);
    }
    newAllValues = { ...newAllValues, [checkboxGroupName]: newValues };
    reversedArrays.forEach((reversedArray) => {
      // @ts-ignore
      let newValues = reversedArraysVales[reversedArray].filter((reversedValue) => !isEqual(reversedValue, value));
      // @ts-ignore
      if (newValues.length !== reversedArraysVales[reversedArray].length) {
        newAllValues = { ...newAllValues, [reversedArray]: newValues };
      }
    });
    change(newAllValues);
    onChange(value, name);
  };
  return (
    <Checkbox
      name={ name }
      checked={ checked || currentNameValues.some((item) => isEqual(item, value)) }
      onChange={ changeValue }
      // @ts-ignore
      disabled={ disabled || value.isDisabled }
      className={ className }
      controlClassName={ controlClassName }
      labelClassName={ labelClassName }
      dataTest={ dataTest }
    />
  );
};

export default BaseCheckboxArray;
