import React from 'react';
import noop from 'lodash/noop';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { Spinner } from '@dealroadshow/uikit';
import styles from './countableButton.scss';

interface IProps {
  className?: string,
  title: string | React.ReactNode,
  selectedCount: number,
  totalCount: number,
  isDisabled: boolean,
  isFetching?: boolean,
  onClick?: (e: object) => void,
  dataTest: string,
}

const CountableButton = ({
  className,
  title,
  selectedCount,
  totalCount,
  isDisabled,
  isFetching = false,
  onClick = noop,
  dataTest,
}: IProps) => {
  const allPostfix = totalCount ? ` (${ totalCount })` : '';
  const preparedTitle = `${ title } ${ selectedCount ? `(${ selectedCount })` : `All${ allPostfix }` }`;

  return (
    <Button
      className={ className }
      variant={ ButtonVariantType.action }
      disabled={ isDisabled }
      onClick={ onClick }
      dataTest={ dataTest }
    >
      <div className={ styles.container }>
        <div>{ preparedTitle }</div>
        <Spinner
          className={ styles.spinner }
          isVisible={ isFetching }
          size="small"
          mode="light"
        />
      </div>
    </Button>
  );
};

export default CountableButton;
