import React, { useState, useEffect, useCallback, useRef } from 'react';
import cn from 'classnames';
import useRouter from '@/Framework/hooks/useNextRouter';
import startsWith from 'lodash/startsWith';
import getDataroomBasedUrl from '@/dataroom/infrastructure/getDataroomBasedUrl';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import useDataroomNavigation from '@/dataroom/application/useDataroomNavigation';
import { Area } from '@/dataroom/domain/vo/Area';
import { TFinalFormRenderProp } from '@/Framework/UI/Organisms/FinalForm/interfaces';
import FinalForm from '@/Framework/UI/Organisms/FinalForm';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import SearchOptions from '@/dataroom/ui/common/SearchOptions';
import { isScreenS } from '@dealroadshow/uikit/core/styles/screen/screen';
import { Icon, IconType } from '@dealroadshow/uikit';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import useIsMobile from '@/Framework/hooks/useIsMobile';
import SearchInput from './SearchInput';
import validate from './validator';
import styles from './search.scss';

interface IProps {
  inputClassName?: string,
  emptyInputClassName?: string,
  isOpened?: boolean,
  setOpened?: (isOpened: boolean) => void,
  isDisabled?: boolean,
}

const baseSearchPath = 'search';

const Search = (
  {
    inputClassName,
    emptyInputClassName,
    isOpened,
    setOpened = () => {
    },
    isDisabled,
  }: IProps) => {
  const formRef = useRef(null);
  const router = useRouter();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const isMobile = useIsMobile(isScreenS);

  const { dataroom } = useDataroomContext();
  const {
    canUserAccessPrimary,
    canUserAccessStaging,
  } = useCurrentUserContext();
  const { goToRootFolder } = useDataroomNavigation();

  const {
    asPath,
    query,
  } = router;
  const pathname = asPath.split('?')[0];

  const isSearchPage = startsWith(pathname, getDataroomBasedUrl(dataroom.name, baseSearchPath));

  useEffect(() => {
    setIsSubmitted(false);
  }, [asPath]);

  useEffect(() => {
    if (!isSearchPage) {
      formRef.current?.restart();
      setOpened(false);
    }
  }, [isSearchPage]);

  const getSearchPathname = (area: Area) => {
    if (canUserAccessPrimary && canUserAccessStaging) {
      return getDataroomBasedUrl(
        dataroom.name,
        `${ baseSearchPath }/${ area }`,
      );
    }

    return getDataroomBasedUrl(dataroom.name, baseSearchPath);
  };

  const handleCloseForm = (reset: () => void) => {
    reset();
    setOpened(false);

    if (isSearchPage) {
      goToRootFolder(dataroom, !canUserAccessPrimary);
    }
  };

  const handleFormSubmit = ({
    searchArea,
    searchText,
    fileContent,
  }) => {
    setIsSubmitted(true);
    const searchPathname = getSearchPathname(searchArea);

    router.push({
      pathname: searchPathname,
      query: { q: searchText, ...(dataroom.docSearchEnabled ? { fileContent } : {}) },
    });
  };

  const renderForm: TFinalFormRenderProp = useCallback(({
    invalid,
    pristine,
    form,
    errors,
    touched,
    values,
  }, {
    Field,
  }) => {
    formRef.current = form;

    return (
      <>
        <div className={ cn(styles.searchWrp, {
          [styles.isOpened]: isOpened,
          [styles.isMobile]: isMobile,
        }) }
        >
          <SearchInput
            fieldComponent={ Field }
            isMobile={ isMobile }
            handleClick={ () => setOpened(true) }
            isFormOpened={ isOpened }
            className={ inputClassName }
            emptyClassName={ emptyInputClassName }
            isError={ isMobile && touched.searchText && !!errors.searchText }
            isEmpty={ !values.searchText }
            isDisabled={ isDisabled }
          />
          { isOpened && !isMobile && (
            <>
              <div className={ styles.optionsWrp }>
                <SearchOptions fieldComponent={ Field } />
              </div>
              <div className={ styles.footerButtons }>
                <Button
                  type="submit"
                  variant={ ButtonVariantType.action }
                  className={ styles.searchBtn }
                  disabled={ invalid || pristine }
                  title={ (
                    <div className={ styles.searchTitleWrp }>
                      { !isSubmitted ? (
                        <>
                          <Icon
                            type={ IconType.search }
                            className={ styles.searchBtnIcon }
                          />
                          Search
                        </>
                      ) : (
                        <Spinner
                          size="small"
                          mode="light"
                        />
                      ) }
                    </div>
                  ) }
                  dataTest="searchButton"
                />
                <Button
                  type="button"
                  variant={ ButtonVariantType.text }
                  className={ styles.cancelBtn }
                  onClick={ () => handleCloseForm(form.restart) }
                  title="Cancel"
                  dataTest="cancelButton"
                />
              </div>
            </>
          ) }
        </div>
      </>
    );
  }, [isOpened, isSearchPage, isSubmitted]);

  return (
    <FinalForm
      name="searchForm"
      dataTest="searchForm"
      initialValues={ {
        searchText: isSearchPage ? query.q?.toString() : '',
        searchArea: isSearchPage && dataroom.stagingEnabled ? query.area : Area.Primary,
        fileContent: isSearchPage ? query.fileContent?.toString() === 'true' : false,
      } }
      onSubmit={ handleFormSubmit }
      validate={ (values) => validate(values, isOpened) }
      render={ renderForm }
    />
  );
};

export default Search;
