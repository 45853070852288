import React from 'react';
import cn from 'classnames';
import { Icon, IconType } from '@dealroadshow/uikit';
import { TabLink as NextNavLink } from '@/Framework/Router/Next/Link';

import styles from './navigationItem.scss';

interface IProps {
  className?: string,
  activeClassName?: string,
  iconClassName?: string,
  textClassName?: string,
  path: string,
  isActive?: boolean,
  icon: IconType,
  title: string,
}

const getDataTestAttributes = (str: string, navLinkName: string) => {
  const strWithoutSpaces = str.split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('');

  return strWithoutSpaces.charAt(0).toLowerCase() + strWithoutSpaces.slice(1) + navLinkName;
};

const NavigationItem = (props: IProps) => (
  <NextNavLink
    to={ props.path }
    isActive={ props.isActive }
    className={ cn(styles.navigationItem, props.className) }
    activeClassName={ cn(styles.navigationActiveItem, props.activeClassName) }
    data-test={ getDataTestAttributes(props.title, 'QuickFilters') }
  >
    <Icon
      type={ props.icon }
      className={ cn(styles.navigationItemIcon, props.iconClassName) }
    />
    <span className={ cn(styles.navigationItemText, props.textClassName) }>
      { props.title }
    </span>
  </NextNavLink>
);

export default NavigationItem;
