import React, { Component } from 'react';
import Clipboard from 'react-clipboard.js';
import cn from 'classnames';
import { Tooltip } from '@dealroadshow/uikit';
import { Placement } from '@popperjs/core';
import styles from './styles.scss';

interface IProps {
  children: React.ReactNode,
  content: any,
  tooltipPlacement?: Placement,
  copyText?: string,
  copiedText?: string,
  disabledText?: string,
  dataTest?: string,
  disabled?: boolean,
}

interface IState {
  copied: boolean,
}

const defaultProps: Partial<IProps> = {
  tooltipPlacement: 'bottom',
  copyText: 'Copy to Clipboard',
  copiedText: 'Copied',
  disabled: false,
  dataTest: 'copyToClipboard',
};
/**
 * @deprecated This component has been chosen for migration to UIKit, meaning any further changes or updates
 * must be completed within the migration process.
 * Note: CopyToClipboardButton should be merged with CopyToClipboard, before moving
 * @see https://www.notion.so/finsight-group/Component-Migration-Process-f4475950481d429ba0dc450d0bb0cb8b
 */
class CopyToClipboard extends Component<IProps, IState> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps: Partial<IProps>;

  constructor(props: IProps) {
    super(props);
    this.state = { copied: false };
    this.onMouseOver = this.onMouseOver.bind(this);
    this.onCopy = this.onCopy.bind(this);
  }

  onMouseOver() {
    this.setState({ copied: false });
  }

  onCopy() {
    this.setState({ copied: true });
  }

  render() {
    return (
      <div
        onMouseOver={ this.onMouseOver }
        className={ cn({
          [styles.disabled]: this.props.disabled,
        }) }
        data-test={ this.props.dataTest }
      >
        <Tooltip
          placement={ this.props.tooltipPlacement }
          // TODO: Should be fixed
          /* eslint-disable-next-line no-nested-ternary */
          content={ this.props.disabled
            ? this.props.disabledText
            : (this.state.copied
              ? this.props.copiedText
              : this.props.copyText
            ) }
        >
          <Clipboard
            component="span"
            data-clipboard-text={ this.props.disabled ? null : this.props.content }
            onSuccess={ this.onCopy }
          >
            { /* @ts-ignore */ }
            { this.props.children }
          </Clipboard>
        </Tooltip>
      </div>
    );
  }
}

CopyToClipboard.defaultProps = defaultProps;

export default CopyToClipboard;
