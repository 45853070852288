import React from 'react';
import cn from 'classnames';
import noop from 'lodash/noop';
import ToolbarIconButton from '@/dataroom/ui/common/ToolbarIconButton';
import CountableButton from '@/Framework/UI/Atoms/Button/CountableButton';
import Button from '@dealroadshow/uikit/core/components/Button';
import { variantTypes } from './constants';
import { Spinner, IconType } from '@dealroadshow/uikit';
import styles from './actionButton.scss';
import IconTextButton from '@/Framework/UI/Atoms/Button/IconTextButton';

interface IProps {
  className?: string,
  buttonClassName?: string,
  title?: string | React.ReactNode,
  totalCount?: number,
  selectedCount?: number,
  iconType?: IconType,
  isFetching?: boolean,
  isDisabled?: boolean,
  variant?: string,
  onClick?: (e: object) => void,
  dataTest: string,
  label?: string,
  icon?: IconType,
}

const ActionButton = ({
  className,
  buttonClassName,
  title,
  totalCount,
  selectedCount,
  iconType,
  isFetching,
  isDisabled = false,
  variant = variantTypes.action,
  onClick = noop,
  label,
  icon,
  dataTest,
}: IProps) => {
  const handleOnClick = (e) => {
    !isDisabled && onClick(e);
  };

  return (
    <div
      className={ cn(className, {
        [styles.textButtonWrp]: variant === variantTypes.text,
        [styles.actionButtonWrp]: variant === variantTypes.action,
        [styles.iconButtonWrp]: variant === variantTypes.icon,
        [styles.countableButtonWrp]: variant === variantTypes.countable,
      }) }
    >
      {
        isFetching &&
        variant === variantTypes.icon &&
        <Spinner className={ styles.spinner } size="small" />
      }
      { variant === variantTypes.text && (
        <div
          className={ cn(buttonClassName, {
            [styles.isDisabled]: isDisabled,
          }) }
          onClick={ handleOnClick }
        >
          { title }
        </div>
      ) }
      { variant === variantTypes.action && (
        <Button
          variant={ variant }
          disabled={ isDisabled || isFetching }
          onClick={ handleOnClick }
          className={ buttonClassName }
          dataTest={ dataTest }
        >
          <span className={ styles.actionButtonTitle }>
            { title }
            { isFetching && <Spinner size="small" className={ cn(styles.spinner, styles.inlineSpinner) } /> }
          </span>
        </Button>
      ) }
      { variant === variantTypes.icon && (
        <ToolbarIconButton
          className={ cn(styles.iconButtonContainer, {
            [styles.isFetchingIconButtonContainer]: isFetching,
          }) }
          buttonClassName={ cn(styles.iconButton, buttonClassName) }
          title={ title }
          onClick={ handleOnClick }
          iconType={ iconType }
          isDisabled={ isDisabled }
          dataTest={ dataTest }
        />
      ) }
      { variant === variantTypes.countable && (
        <CountableButton
          className={ buttonClassName }
          isFetching={ isFetching }
          isDisabled={ isDisabled }
          title={ title }
          totalCount={ totalCount }
          selectedCount={ selectedCount }
          onClick={ handleOnClick }
          dataTest={ dataTest }
        />
      ) }
      { variant === variantTypes.textWithIcon && (
        <IconTextButton
          className={ buttonClassName }
          isFetching={ isFetching }
          isDisabled={ isDisabled }
          onClick={ handleOnClick }
          dataTest={ dataTest }
          label={ label || title }
          icon={ icon || iconType }
        />
      ) }
    </div>
  );
};

export default ActionButton;
