import { FloatingTooltip, Spinner } from '@dealroadshow/uikit';
import { useDownloadArchiveStatusContext } from '@/dataroom/application/DownloadArchiveStatusContext';
import styles from './preparingArchivesCount.scss';

const PreparingArchivesCount = () => {
  const { archiveCount, isVisibleCount, setIsVisibleCount } = useDownloadArchiveStatusContext();

  if (!archiveCount) {
    return null;
  }

  return (
    <FloatingTooltip
      className={ styles.wrapper }
      tooltipClassName={ styles.tooltip }
      visible={ isVisibleCount }
      onClick={ () => setIsVisibleCount(false) }
      dataTest="preparingDownloadCount"
    >
      <Spinner className={ styles.spinner } />
      <span>Preparing Download: { archiveCount } archive{ archiveCount > 1 ? 's' : '' }</span>
    </FloatingTooltip>
  );
};

export default PreparingArchivesCount;
