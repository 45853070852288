import React from 'react';
import { Input } from '@dealroadshow/uikit';

const isTextFieldChanged = (value, previousValue) => (
  value?.trim() === previousValue?.trim()
);

interface IProps {
  name: string,
  dataTest: string,
  fieldComponent: React.ElementType,
  className?: string,
  label?: string,
  placeholder?: string,
  component?: React.ComponentType,
  onChange?: (e: any, suggestion: any, options: any) => void,
  onBlur?: (e: any) => void,
  onFocus?: (e: any) => void,
  isNarrow?: boolean,
}

const TextField = (
  {
    name,
    dataTest,
    fieldComponent: Field,
    className,
    label,
    placeholder,
    component: Component = Input,
    ...props
  }: IProps,
) => {
  const handleFieldBlur = (input, event) => {
    const value = typeof event === 'string' ? event : event.target?.value;
    input.onChange(value?.trim() || '');
    input.onBlur(event);
  };

  return (
    // @ts-ignore
    <Field
      { ...props }
      label={ label }
      name={ name }
      placeholder={ placeholder }
      className={ className }
      dataTest={ dataTest }
      isEqual={ isTextFieldChanged }
    >
      { ({ input, ...props }) => (
        <Component
          { ...props }
          // @ts-ignore
          input={ {
            ...input,
            onBlur: handleFieldBlur.bind(null, input),
          } }
        />
      ) }
    </Field>
  );
};

export default TextField;
