import { Dependencies } from 'constitute';
import { v4 as uuid } from 'uuid';
import { Notification, RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import { SortOrder } from '@dealroadshow/uikit';
import Request from '@/Framework/api/Rpc/Request';
import JsonRpcDispatcherFactory from '@/dataroom/application/DI/Rpc/HttpDispatcher';
import AbstractDataroomRepository from '@/dataroom/infrastructure/repository/AbstractDataroomRepository';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import { PermissionGroup } from '@/dataroom/domain/vo/types/PermissionGroup';
import { FileIndexShiftMethod } from '@/dataroom/domain/vo/filesystem/FileIndexShiftMethod';
import { IFolderPermissionGroup } from '@/dataroom/domain/vo/filesystem/FolderPermissionGroup';
import { IFolderInfo } from '@/dataroom/domain/vo/filesystem/FolderInfo';
import SocketClientFactory from '@/dataroom/application/DI/Socket/Client';

@Dependencies(JsonRpcDispatcherFactory, SocketClientFactory)
class FolderRepository extends AbstractDataroomRepository {
  constructor(protected rpc: typeof JsonRpcDispatcherFactory, private socketClient: typeof SocketClientFactory) {
    super(rpc);
  }

  getListing = async (
    payload: {
      dataroomId: number,
      withFilesCount: boolean,
      folderId: number,
      perPage: number,
      sortBy: string,
      sortOrder: SortOrder,
      page: number,
    },
  ): Promise<{
    collection: IFilesystemListItem[],
    totalCount: number,
  }> => this.abortableCall(new Request('dataroom.filesystem.folder.get_child_elements', payload));

  getInfo = async (
    payload: {
      dataroomId: number,
      folderId: number,
    },
  ): Promise<IFolderInfo> => this.abortableCall(new Request('dataroom.filesystem.folder.get_info', payload));

  getCustomPermissions = async (payload: {
    dataroomId: number,
    folderId: number,
  }): Promise<IFolderPermissionGroup[]> => {
    const request = new Request('dataroom.filesystem.folder.custom_permissions', payload);
    const response = await this.rpc().call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  getCustomEditPermissions = async (payload: {
    dataroomId: number,
    folderId: number,
  }): Promise<IFolderPermissionGroup[]> => {
    const request = new Request('dataroom.filesystem.folder.custom_permissions_with_mixed', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getAllEditPermissions = async (payload: {
    dataroomId: number,
    folderId: number,
  }): Promise<IFolderPermissionGroup[]> => {
    const request = new Request('dataroom.filesystem.folder.all_permissions_with_mixed', payload);
    const response = await this.rpc().call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  getTree = async (
    payload: {
      dataroomId: number,
    },
  ): Promise<IFolderTree> => {
    const request = new Request('dataroom.filesystem.tree.folder_tree', payload);
    const response = await this.rpc().call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  createFolders = async (payload: {
    dataroomId: number,
    folders: {
      name: string,
      destination: {
        id: number,
      },
    }[],
    customPermissionGroups: {
      id: number,
      permission: PermissionGroup,
    }[],
  }): Promise<void> => {
    const request = new Request('dataroom.filesystem.bulk.folders.create', payload);
    const response = await this.rpc().call<RpcSuccess>(request);
    return response.getResult();
  };

  edit = async (
    payload: {
      dataroomId: number,
      folderId: number,
      folderName: string,
      customPermissionGroups: {
        id: number,
        permission: string,
      }[],
      onFinish: () => void,
      onError: (error: { code: number }) => void,
    },
  ): Promise<void> => {
    const requestUuid = uuid();
    const { onFinish, onError, ...requestPayload } = payload;

    const subscribeReq = new Request('dataroom.filesystem_element_permission_groups.update.listen', {
      uuid: requestUuid,
    });

    const subscription = await this.socketClient().subscribe(subscribeReq);

    subscription
      .on('filesystem_element_permission_groups.update.completed', () => {
        subscription.cancel();
        onFinish();
      })
      .on('filesystem_element_permission_groups.update.error', (notification: Notification) => {
        subscription.cancel();
        onError(notification.params.payload || {});
      });

    const request = new Request('dataroom.filesystem.folder.edit', {
      filesystemElementSessionUuid: requestUuid,
      ...requestPayload,
    });
    const response = await this.rpc().call<RpcSuccess>(request);
    return response.getResult();
  };

  changeIndex = async (payload: {
    dataroomId: number,
    folderId: number,
    index: number,
    overrideMethod?: FileIndexShiftMethod,
  }): Promise<void> => {
    const request = new Request('dataroom.filesystem.folder.change_index', payload);
    const response = await this.rpc().call<RpcSuccess>(request);
    return response.getResult();
  };

  getNameWithCustomIndex = async (payload: {
    dataroomId: number,
    folderId: number,
    index: number,
  }): Promise<{
    name: string,
    exist: boolean,
  }> => {
    const request = new Request('dataroom.filesystem.folder.custom_exists', payload);
    const response = await this.rpc().call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  unpin = async (payload: {
    dataroomId: number,
    folderId: number,
  }): Promise<void> => {
    const request = new Request('dataroom.filesystem.folder.unpin', payload);
    const response = await this.rpc().call<RpcSuccess>(request);
    return response.getResult();
  };

  disable = async (payload: {
    dataroomId: number,
    audioUploading: boolean,
    emailUploading: boolean,
  }): Promise<void> => {
    const request = new Request('dataroom.system_folders.disable', payload);
    const response = await this.rpc().call<RpcSuccess>(request);
    return response.getResult();
  };

  addChildren = async (
    payload: {
      dataroomId: number,
      folderId: number,
      customPermissionGroups: {
        id: number,
        permission: PermissionGroup,
      }[],
      children: {
        id?: string,
        type: string,
      }[],
    },
    getCallback: () => (percentage: number) => void,
  // eslint-disable-next-line no-async-promise-executor
  ): Promise<boolean> => new Promise(async (resolve, reject) => {
    const uploadSessionId = uuid();
    const subscribeRequest = new Request('dataroom.filesystem.folder.add_children.listen', { uploadSessionId });
    const socketClient = this.socketClient();
    const processSubscription = await socketClient.subscribe(subscribeRequest);
    processSubscription
      .on('add_children.in_progress', (notification: Notification) => {
        getCallback()(notification.params.payload.percentage);
      })
      .on('add_children.processed', (notification: Notification, subscription) => {
        subscription.cancel();
        resolve(notification.params.payload);
      })
      .on('add_children.error', (notification: Notification, subscription) => {
        subscription.cancel();
        reject(notification.params.payload);
      });

    const req = new Request('dataroom.filesystem.folder.add_children', {
      ...payload,
      uploadSessionId,
    });
    return this.rpc()
      .call<RpcSuccess>(req)
      .catch((e) => reject(e));
  });

  getRecursiveListing = async (
    payload: {
      dataroomId: number,
      withFilesCount: boolean,
      folderId: number,
      page: number,
      perPage: number,
      search: string,
    },
  ): Promise<IFolderTree[]> => this.abortableCall(new Request('dataroom.filesystem.folder.search', payload));
}

export default FolderRepository;
