import React from 'react';
import intersection from 'lodash/intersection';
import { Checkbox } from '@dealroadshow/uikit';
import { allItems, defaultCheckboxGroupName, disabledIds, IValues } from '@/Framework/UI/Molecules/CheckBoxArray/constants';

interface IProps {
  allValues: IValues,
  reversedArrays?: [],
  label?: string | React.ReactNode,
  name: string,
  change: (newAllValues: IValues) => void,
  isUnCheckEnable?: boolean,
  isCheckEnable?: boolean,
  supportIndeterminate?: boolean,
  className?: string,
  controlClassName?: string,
  labelClassName?: string,
  checkboxGroupName?: string,
  dataTest: string,
  disabled?: boolean,
}

const BaseCheckAll = (
  {
    allValues,
    change,
    name,
    checkboxGroupName = defaultCheckboxGroupName,
    reversedArrays = [],
    label,
    isUnCheckEnable = true,
    isCheckEnable = true,
    supportIndeterminate = false,
    className,
    controlClassName,
    labelClassName,
    dataTest,
    disabled,
  }: IProps,
) => {
  let currentGroupValues = allValues[checkboxGroupName] || [];
  let isAllChecked = false;
  let isSomeChecked = false;
  const allGroupValues = (allValues[allItems] || [])
    // @ts-ignore
    .filter((item) => !(item.isDisabled || (allValues[disabledIds] && allValues[disabledIds].includes(item))));

  if (allGroupValues && allGroupValues.length) {
    isAllChecked = allGroupValues.every((item) => (currentGroupValues.includes(item)));
    isSomeChecked = allGroupValues.some((item) => (currentGroupValues.includes(item)));
  }

  const changeValue = () => {
    let newAllValues = allValues;
    if (isAllChecked && isUnCheckEnable) {
      newAllValues = {
        ...newAllValues,
        [checkboxGroupName]: [],
      };
      change(newAllValues);
    } else if (isCheckEnable) {
      const currentDisabledIds = intersection(allValues[disabledIds], allValues[checkboxGroupName]);
      newAllValues = {
        ...newAllValues,
        [checkboxGroupName]: [...allGroupValues, ...currentDisabledIds],
      };

      reversedArrays.forEach((reversedArray) => {
        const reversedArrayDisabledIds = intersection(newAllValues[reversedArray], allValues[disabledIds]);
        newAllValues = {
          ...newAllValues,
          [reversedArray]: reversedArrayDisabledIds,
        };
      });

      change(newAllValues);
    }
  };

  return (
    <Checkbox
      name={ name }
      label={ label }
      checked={ isAllChecked }
      indeterminate={ isSomeChecked }
      supportIndeterminate={ supportIndeterminate }
      onChange={ changeValue }
      className={ className }
      controlClassName={ controlClassName }
      labelClassName={ labelClassName }
      disabled={ allGroupValues.length === 0 || disabled }
      dataTest={ dataTest }
    />
  );
};

export default BaseCheckAll;
