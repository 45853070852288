import React, { useCallback } from 'react';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import FinalForm from '@/Framework/UI/Organisms/FinalForm';
import Input from '@/Framework/UI/Molecules/Form/FinalFormInput';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import responsiveModalStyles from '@dealroadshow/uikit/core/components/Modal/responsiveModal.scss';
import { CHANGE_INDEX_CONFLICT_FORM } from '../constants';
import validate from './validator';
import { getParentIndexArray, isFolder } from '@/dataroom/domain/filesystem';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';
import { TFinalFormRenderProp } from '@/Framework/UI/Organisms/FinalForm/interfaces';
import styles from './changeIndexForm.scss';

interface IProps {
  initialValues: {},
  item: IFilesystemListItem & { parent?: IFolderTree & { childrenCount: number } },
  isFetching: boolean,
  isFormLoading: boolean,
  maxIndex: number,
  onChangeType: (value: string) => void,
  onChangeIndex: (index: number) => void,
  closeModal: () => void,
  changeIndex: (item: IFilesystemListItem, index: number) => Promise<void>,
  checkCustomExists: (item: IFilesystemListItem, index: number) => Promise<any>,
  unpinIndex: (item: IFilesystemListItem) => Promise<void>,
  footerRefCallback: () => void,
}

const ChangeIndexForm = (
  {
    initialValues,
    item,
    isFetching,
    isFormLoading,
    maxIndex,
    onChangeType,
    onChangeIndex,
    closeModal,
    changeIndex,
    checkCustomExists,
    unpinIndex,
    footerRefCallback,
  }: IProps,
) => {
  const parentIndexArray = getParentIndexArray(item.fileIndexFull);

  const handleFormSubmit = (formData) => checkCustomExists(item, formData.fileIndexCurrent)
    .then(({ isCustomExists, hasError }) => {
      if (hasError) return;

      if (isCustomExists) {
        onChangeType(CHANGE_INDEX_CONFLICT_FORM);
        return;
      }
      changeIndex(item, formData.fileIndexCurrent).then(closeModal);
    });

  const handleIndexChange = (event) => {
    !isFetching && onChangeIndex(event.target.value);
  };

  const indexPrefix = parentIndexArray.reduce((acc, indexValue, index) => {
    const key = `${ index }.${ indexValue }`;

    acc.push((
      <span key={ key }>
        <span
          className={ styles.indexValue }
        >
          { indexValue }
        </span>
        { (index !== parentIndexArray.length - 1) && (
          <span
            className={ styles.delimiter }
          >
            .
          </span>
        ) }
        { (index === parentIndexArray.length - 1) && (
          <span
            className={ styles.delimiter }
          >
            { isFolder(item) ? '.' : ' -' }
          </span>
        ) }
      </span>
    ));

    return acc;
  }, []);

  const changeIndexFormValidate = useCallback((values) => validate(maxIndex, values), [maxIndex]);

  const indexPostfix = isEmpty(parentIndexArray) && (
    <>
      <span className={ styles.delimiter }>.</span>
      <span className={ cn(styles.indexValue, styles.withSpace) }>0</span>
    </>
  );

  const renderForm: TFinalFormRenderProp = useCallback(({
    invalid,
  }, {
    Field,
  }) => (isFormLoading ? null : (
    <>
      <div className={ styles.contentWrp }>
        { isFetching && <Spinner overlay /> }
        <div className={ styles.disclaimer }>
          Please select a number between 1 and { maxIndex }. Note that this change will
          re-index all subfolders and files accordingly.
        </div>
        <div className={ styles.indexWrp }>
          { item.isStaging && <span className={ cn(styles.indexValue, styles.stagingPrefix) } /> }
          { indexPrefix }
          <div className={ styles.currentIndexControl }>
            <Field
              name="fileIndexCurrent"
              className={ cn(styles.currentIndex, {
                [styles.withSpace]: !isEmpty(parentIndexArray),
              }) }
              component={ Input }
              onChange={ handleIndexChange }
              isClearable={ false }
              isNarrow
              dataTest="fileIndexInput"
            />
          </div>
          { indexPostfix }
          <div className={ styles.explanation }>
            for the { isFolder(item) ? 'folder' : 'file' }:{ ' ' }
            <Tooltip
              content={ item.name }
              wrpClassName={ styles.nameWrp }
              containerClassName={ styles.nameContainer }
            >
              <span className={ styles.name }>{ item.name }</span>
            </Tooltip>
          </div>
        </div>
      </div>
      <div
        className={ cn(styles.buttonsWrp, responsiveModalStyles.modalFooter, {
          [styles.isFileIndexCustom]: item.isFileIndexCustom,
        }) }
        ref={ footerRefCallback }
      >
        <Button
          type="submit"
          variant={ ButtonVariantType.action }
          disabled={ invalid || isFetching }
          title="Update Index"
          dataTest="changeIndexModalUpdateIndexButton"
        />
        <div>
          { item.isFileIndexCustom && (
            <Button
              variant={ ButtonVariantType.action }
              className={ styles.unpinButton }
              disabled={ isFetching }
              onClick={ () => unpinIndex(item).then(closeModal) }
              dataTest="changeIndexModalUnpinIndexedPositionButton"
            >
              Unpin Indexed Position
            </Button>
          ) }
          <Button
            variant={ ButtonVariantType.text }
            onClick={ closeModal }
            dataTest="changeIndexModalCancelButton"
          >
            Cancel
          </Button>
        </div>
      </div>
    </>
  )), [
    isFetching,
    isFormLoading,
    maxIndex,
  ]);

  return (
    <FinalForm
      name="changeIndexForm"
      dataTest="changeIndexForm"
      initialValues={ initialValues }
      onSubmit={ handleFormSubmit }
      render={ renderForm }
      validate={ changeIndexFormValidate }
    />
  );
};

export default ChangeIndexForm;
